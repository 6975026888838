import React from 'react';
import { useTranslation } from 'react-i18next';
import { parseISO, min, max } from 'date-fns'
import { Redirect } from 'react-router';

import Text from 'components/text';
import { Date, Time } from 'components/i18n';
import Link from 'components/link';
import {
  Table,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table';
import { Election, ElectionGroup } from 'interfaces';

interface IProps {
  electionGroups: Array<ElectionGroup>;
  hasVotedElectionGroup: string[];
  votingRightsElectionGroups: string[];
  noElectionsText: React.ReactElement;
}

function electionOngoing(electionGroup: ElectionGroup) {
  if (electionGroup.status === 'ongoing') {
    return true;
  }
  if (electionGroup.status === 'multipleStatuses') {
    const electionStatuses = electionGroup.elections.map(
      (e: Election) => e.status
    );
    if (electionStatuses.includes('ongoing')) {
      return true;
    }
  }
  return false;
}

export default function VoterElectionsTable(props: IProps) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { electionGroups, noElectionsText } = props;
  const [redirect, setRedirect] = React.useState<string>('');

  const dateTimeToMarkup = (dateTime: Date | string) => (
    <>
      <Text>
        <Date dateTime={dateTime} longDate />
      </Text>
      <Text size="small">
        <Time dateTime={dateTime} />
      </Text>
    </>
  );

  return redirect ? (
    <Redirect push to={redirect} />
  ) : (
    <Table>
      <TableHeader key="thead">
        <TableHeaderRow>
          <TableHeaderCell>{t('election.election')}</TableHeaderCell>
          <TableHeaderCell>{t('election.opens')}</TableHeaderCell>
          <TableHeaderCell>{t('election.closes')}</TableHeaderCell>
          <TableHeaderCell>{t('election.rightToVote')}</TableHeaderCell>
          <TableHeaderCell />
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {electionGroups.length === 0 && (
          <TableRow>
            <TableCell colspan={5}>
              <Text>{noElectionsText}</Text>
            </TableCell>
          </TableRow>
        )}
        {electionGroups.map((group) => {
          let startTime: Date | string;
          let endTime: Date | string;

          if (group.elections.length === 1) {
            startTime = group.elections[0].start;
            endTime = group.elections[0].end;
          } else {
            startTime = min(group.elections.map((e) => parseISO(e.start)));
            endTime = max(group.elections.map((e) => parseISO(e.end)));
          }

          let canVote = false;
          if (props.votingRightsElectionGroups.includes(group.id)) {
            canVote = true;
          }

          const showVoteLink = electionOngoing(group);

          const rowOnClick = showVoteLink
            ? () => setRedirect(`/vote/${group.id}`)
            : undefined;

          let hasVoted = false;
          if (props.hasVotedElectionGroup.includes(group.id)) {
            hasVoted = true;
          }

          return (
            <TableRow key={group.id} tall onClick={rowOnClick}>
              <TableCell maxWidth="50rem">
                <Text>{group.name[lang]}</Text>
              </TableCell>
              <TableCell>{dateTimeToMarkup(startTime)}</TableCell>
              <TableCell>{dateTimeToMarkup(endTime)}</TableCell>
              <TableCell alignCenter>
                <Text>{canVote ? t('general.yes') : t('general.no')}</Text>
              </TableCell>
              {showVoteLink ? (
                <TableCell noPadding>
                  {!hasVoted ? (
                    <Link to={`/vote/${group.id}`}>
                      {t('election.voteNow')}
                    </Link>
                  ) : (
                    <Link to={`/vote/${group.id}`}>
                      {t('election.changeVote')}
                    </Link>
                  )}
                </TableCell>
              ) : (
                <TableCell>
                  <></>
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
