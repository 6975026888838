import { EditListCandidate, ElectionList } from 'interfaces';

/**
 * Create the ballot data for a list votes with edits.
 *
 * @param selectedList The list voted for.
 * @param editedCandidates The new updated candidate order and cumulate status for the list.
 * @param otherListCandidates Any personal votes for candidates in other lists.
 * @returns
 */
export function createEditListBallot(
  selectedList: ElectionList,
  editedCandidates: EditListCandidate[],
  otherListCandidates: EditListCandidate[]
) {
  return {
    voteType: 'SPListElecVote',
    chosenListId: selectedList.id,
    isBlankVote: false,
    personalVotesOtherParty: otherListCandidates.map((candidate) => ({
      candidate: candidate.candidate.id,
      list: candidate.sourceList.id,
    })),
    personalVotesSameParty: editedCandidates
      .filter((candidate) => !candidate.userDeleted)
      .map((candidate) => ({
        candidate: candidate.candidate.id,
        cumulated: candidate.userCumulated,
      })),
  };
}

/**
 * Create the ballot data for a blank list vote.
 *
 * @returns Blank vote ballot data
 */
export function createBlankListBallot() {
  return {
    voteType: 'SPListElecVote',
    chosenListId: '',
    isBlankVote: true,
    personalVotesOtherParty: [],
    personalVotesSameParty: [],
  };
}

/**
 * Creates ballot data for a list vote with no changes
 *
 * @param electionList Election list we want to vote for
 * @returns Ballot data for a election vote with no changes.
 */
export function createCleanListBallot(electionList: ElectionList) {
  const candidatesAfterPriority = [...electionList.candidates].sort(
    (a, b) => a.priority - b.priority
  );
  return {
    voteType: 'SPListElecVote',
    chosenListId: electionList.id,
    isBlankVote: false,
    personalVotesOtherParty: [],
    personalVotesSameParty: candidatesAfterPriority.map((candidate) => ({
      candidate: candidate.id,
      cumulated: false,
    })),
  };
}

/**
 * Get the number of candidates that will get a vote in an edited list vote.
 *
 * nr of seats - pre cumulated - cumulated - nr of other candidates.
 *
 * @param nrOfSeats Nr of seats to elect
 * @param otherListCandidates Votes to candidates in other lists
 * @param editedCandidates The edited list of candidates.
 * @returns Number of possible candidates on a ballot
 */
export function getMaxCandidatesInEditedList(
  nrOfSeats: number,
  editedCandidates: EditListCandidate[] = [],
  otherListCandidates: EditListCandidate[] = []
) {
  const nrOfPrecumulated: number = editedCandidates
    .map((candidate) => {
      if (candidate.candidate.preCumulated && !candidate.userDeleted) {
        return 1;
      }
      return 0;
    })
    .reduce((a: number, b: number) => a + b, 0);

  const nrOfCumulated: number = editedCandidates
    .map((candidate) => {
      if (candidate.userCumulated && !candidate.userDeleted) {
        return 1;
      }
      return 0;
    })
    .reduce((a: number, b: number) => a + b, 0);

  return (
    nrOfSeats - nrOfPrecumulated - nrOfCumulated - otherListCandidates.length
  );
}

/**
 * Get the max number of candidates that will get a vote in a clean list vote.
 *
 * Nr of seats - nr of precumulations.
 *
 * @param nrOfSeats Nr of seats to elect
 * @param electionList The elect list to use
 * @returns Number of possible candidates on a ballot
 */
export function getMaxCandidatesInList(
  nrOfSeats: number,
  electionList: ElectionList
) {
  const nrOfPrecumulated: number = electionList.candidates
    .map((candidate) => {
      if (candidate.preCumulated) {
        return 1;
      }
      return 0;
    })
    .reduce((a: number, b: number) => a + b, 0);

  return nrOfSeats - nrOfPrecumulated;
}
