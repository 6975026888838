import { useTranslation } from 'react-i18next';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  logoBar: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: theme.appMaxWidth,
    padding: `0 ${theme.horizontalPadding}`,
    [theme.breakpoints.mdQuery]: {
      padding: `0 ${theme.horizontalMdPadding}`,
    },
  },
  logoBarWrapper: {
    backgroundColor: theme.headerMainAreaColor,
  },
  logo: {
    marginTop: '3rem',
    marginBottom: '1rem',
    maxHeight: '9rem',
    maxWidth: '100%',
    backgroundSize: 'contain',
  },
}));

export default function HVLLogoBar() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { i18n } = useTranslation();

  let path: string;

  switch (i18n.language) {
    case 'en':
      path = '/hvl/logo_en.png';
      break;
    default:
      path = '/hvl/logo_no.png';
  }

  return (
    <div className={classes.logoBarWrapper}>
      <div className={classes.logoBar}>
        <a href="https://hvl.no">
          <img className={classes.logo} src={path} alt="HVL logo" />
        </a>
      </div>
    </div>
  );
}
