import { useTranslation } from 'react-i18next';
import { createUseStyles, useTheme } from 'react-jss';

import Link from 'components/link';
import { Election } from 'interfaces';
import MandatePeriodText from './MandatePeriodText';

const useStyles = createUseStyles((theme: any) => ({
  electionGroupInfoSection: {
    marginBottom: '3rem',
    [theme.breakpoints.mdQuery]: {
      marginBottom: '4rem',
    },
  },
  mandatePeriodTextDesktop: {
    display: 'none',
    [theme.breakpoints.mdQuery]: {
      display: 'inherit',
      ...theme.ingress,
    },
  },
  mandatePeriodTextMobile: {
    [theme.breakpoints.mdQuery]: {
      display: 'none',
    },
  },
}));

export default function ElectionInfo({ election }: { election: Election }) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  return (
    <div className={classes.electionGroupInfoSection}>
      <div className={classes.mandatePeriodTextDesktop}>
        <MandatePeriodText election={election} longDate />
      </div>
      <div className={classes.mandatePeriodTextMobile}>
        <MandatePeriodText election={election} />
      </div>
      {election.informationUrl && (
        <p>
          {t('voterGroupSelect.moreAboutTheElection')}:{' '}
          <Link to={election.informationUrl} external>
            {election.informationUrl}
          </Link>
        </p>
      )}
      {election.contact && (
        <p>
          {t('voterGroupSelect.contact')}{' '}
          <Link to={election.contact} mail>
            {election.contact}
          </Link>
        </p>
      )}
    </div>
  );
}
