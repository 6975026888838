import theme from 'theme';

const uiaBlack = '#131E29';
const uiaRed = '#C8102E';

const UiATheme = {
  actionTextColor: uiaRed,
  dropDownDesktopMenuColor: theme.colors.white,
  footerBackgroundColor: uiaBlack,
  footerTextColor: theme.colors.white,
  headerDescColor: theme.colors.white,
  headerMainAreaColor: uiaRed,
  linkColor: theme.colors.white,
  loginPageBgColor: theme.colors.white,
};

export default UiATheme;
