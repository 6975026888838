import { appInst } from 'appConfig';
import HiOFTheme from './HiOF';
import HiMoldeTheme from './HiMolde';
import HVLTheme from './HVL';
import INNTheme from './INN';
import KHiOTheme from './KHiO';
import NMBUTheme from './NMBU';
import NMHTheme from './NMH';
import OsloMetTheme from './OsloMet';
import UiATheme from './UiA';
import UiBTheme from './UiB';
import UiSTheme from './UiS';
import UiTTheme from './UiT';
import USNTheme from './USN';
import NHHTheme from './NHH';

/**
 * Get the theme patch for the current institution.
 */
const getCurrentThemePatch = () => {
  switch (appInst) {
    case 'hiof':
      return HiOFTheme;
    case 'himolde':
      return HiMoldeTheme;
    case 'hvl':
      return HVLTheme;
    case 'inn':
      return INNTheme;
    case 'khio':
      return KHiOTheme;
    case 'nhh':
      return NHHTheme;
    case 'nmbu':
      return NMBUTheme;
    case 'nmh':
      return NMHTheme;
    case 'oslomet':
      return OsloMetTheme;
    case 'uia':
      return UiATheme;
    case 'uib':
      return UiBTheme;
    case 'uio':
      return {};
    case 'uis':
      return UiSTheme;
    case 'uit':
      return UiTTheme;
    case 'usn':
      return USNTheme;
    default:
      return {};
  }
};

export default getCurrentThemePatch;
