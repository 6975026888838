import { gql, useMutation } from '@apollo/client';
import arrayMutators from 'final-form-arrays';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation, TFunction } from 'react-i18next';

import {
  DateInputRF,
  FormButtons,
  FormErrorMsg,
  FormField,
  FormFieldGroup,
  RadioButtonGroup,
} from 'components/form';

import { TextInput } from 'components/newForm';

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/table';
import { PageSubSection } from 'components/page';
import Text from 'components/text';
import { validateEmail, validateUrl } from 'utils/validators';
import { Election, ElectionGroup, ElectionVoterInfoInput } from 'interfaces';

const updateVoterInfoQuery = gql`
  mutation UpdateVoterInfo($elections: [ElectionVoterInfoInput]!) {
    updateVoterInfo(elections: $elections) {
      ok
    }
  }
`;

interface VoterInfoData {
  updateBaseSettings: {
    ok: boolean;
  };
}
interface VoterInfo {
  elections: ElectionVoterInfoInput[];
}

function validate(
  values: VoterInfoFormFieldPros,
  t: TFunction,
  language: string,
  activeElections: Election[]
) {
  const elecErrors: any[] = [];
  values.elections.forEach(() => elecErrors.push({}));
  const formErrors: any = {
    mandateDates: [],
    contact: [],
    informationUrl: [],
  };
  const {
    elections,
    hasMultipleMandateTimes,
    hasMultipleContactInfo,
    hasMultipleInfoUrls,
  } = values;

  if (!hasMultipleMandateTimes) {
    const { mandatePeriodStart, mandatePeriodEnd } = elections[0];
    if (
      mandatePeriodStart &&
      mandatePeriodEnd &&
      mandatePeriodStart >= mandatePeriodEnd
    ) {
      formErrors.mandateDates.push({
        msg: t('formErrors.invalidDates', { ns: 'common' }),
        index: 0,
      });
      Object.assign(elecErrors[0], {
        mandatePeriodStart: true,
        mandatePeriodEnd: true,
      });
    }
  } else {
    elections.forEach((e: any, index: number) => {
      const { mandatePeriodStart, mandatePeriodEnd } = e;
      if (
        mandatePeriodStart &&
        mandatePeriodEnd &&
        mandatePeriodStart >= mandatePeriodEnd
      ) {
        formErrors.mandateDates.push({
          msg: (
            <span>
              {activeElections[index].name[language]}:&nbsp;
              {t('formErrors.invalidDates', { ns: 'common' })}
            </span>
          ),
          index,
        });
        Object.assign(elecErrors[index], {
          mandatePeriodStart: true,
          mandatePeriodEnd: true,
        });
      }
    });
  }

  if (!hasMultipleContactInfo) {
    const { contact } = elections[0];
    if (contact && !validateEmail(contact)) {
      formErrors.contact.push({
        msg: t('formErrors.invalidEmail', { ns: 'common' }),
        index: 0,
      });
      Object.assign(elecErrors[0], { contact: true });
    }
  } else {
    elections.forEach((e: any, index: number) => {
      const { contact } = e;
      if (contact && !validateEmail(contact)) {
        formErrors.contact.push({
          msg: (
            <span>
              {activeElections[index].name[language]}:&nbsp;
              {t('formErrors.invalidEmail', { ns: 'common' })}
            </span>
          ),
          index,
        });
        Object.assign(elecErrors[index], { contact: true });
      }
    });
  }

  if (!hasMultipleInfoUrls) {
    const { informationUrl } = elections[0];
    if (informationUrl && !validateUrl(informationUrl)) {
      formErrors.informationUrl.push({
        msg: t('formErrors.invalidUrl', { ns: 'common' }),
        index: 0,
      });
      Object.assign(elecErrors[0], { informationUrl: true });
    }
  } else {
    elections.forEach((e: any, index: number) => {
      const { informationUrl } = e;
      if (informationUrl && !validateUrl(informationUrl)) {
        formErrors.informationUrl.push({
          msg: (
            <span>
              {activeElections[index].name[language]}:&nbsp;
              {t('formErrors.invalidUrl', { ns: 'common' })}
            </span>
          ),
          index,
        });
        Object.assign(elecErrors[index], { informationUrl: true });
      }
    });
  }
  return { elections: elecErrors, formErrors };
}

function buildInitialValues(
  electionGroup: ElectionGroup
): VoterInfoFormFieldPros {
  const elections = electionGroup.elections
    .filter((e) => e.active)
    .map((e) => ({
      id: e.id,
      mandatePeriodStart: e.mandatePeriodStart,
      mandatePeriodEnd: e.mandatePeriodEnd,
      contact: e.contact ?? '',
      informationUrl: e.informationUrl,
    }));
  let hasMultipleMandateTimes = false;

  if (electionGroup.elections.length > 1) {
    for (let i = 0; i < electionGroup.elections.length - 1; i += 1) {
      if (
        electionGroup.elections[i].mandatePeriodStart !==
          electionGroup.elections[i + 1].mandatePeriodStart ||
        electionGroup.elections[i].mandatePeriodEnd !==
          electionGroup.elections[i + 1].mandatePeriodEnd
      ) {
        hasMultipleMandateTimes = true;
        break;
      }
    }
  }

  let hasMultipleContactInfo = false;
  if (electionGroup.elections.length > 1) {
    for (let i = 0; i < electionGroup.elections.length - 1; i += 1) {
      if (
        electionGroup.elections[i].contact !==
        electionGroup.elections[i + 1].contact
      ) {
        hasMultipleContactInfo = true;
        break;
      }
    }
  }
  let hasMultipleInfoUrls = false;
  if (electionGroup.elections.length > 1) {
    for (let i = 0; i < electionGroup.elections.length - 1; i += 1) {
      if (
        electionGroup.elections[i].informationUrl !==
        electionGroup.elections[i + 1].informationUrl
      ) {
        hasMultipleInfoUrls = true;
        break;
      }
    }
  }
  return {
    elections,
    hasMultipleMandateTimes,
    hasMultipleContactInfo,
    hasMultipleInfoUrls,
  };
}

function buildPayload(values: VoterInfoFormFieldPros): VoterInfo {
  const {
    hasMultipleContactInfo,
    hasMultipleInfoUrls,
    hasMultipleMandateTimes,
    elections,
  } = values;

  return {
    elections: elections.map((e: ElectionVoterInfoInput) => ({
      id: e.id,
      mandatePeriodStart: hasMultipleMandateTimes
        ? e.mandatePeriodStart
        : elections[0].mandatePeriodStart,
      mandatePeriodEnd: hasMultipleMandateTimes
        ? e.mandatePeriodEnd
        : elections[0].mandatePeriodEnd,
      contact: hasMultipleContactInfo ? e.contact : elections[0].contact,
      informationUrl: hasMultipleInfoUrls
        ? e.informationUrl
        : elections[0].informationUrl,
    })),
  };
}

interface VoterInfoFormFieldPros {
  elections: ElectionVoterInfoInput[];
  hasMultipleMandateTimes: boolean;
  hasMultipleContactInfo: boolean;
  hasMultipleInfoUrls: boolean;
}

interface VoterInfoFormProps {
  closeAction: () => void;
  electionGroup: ElectionGroup;
  onSubmit: () => void;
}

export default function VoterInfoForm({
  closeAction,
  electionGroup,
  onSubmit,
}: VoterInfoFormProps) {
  const {
    i18n: { language },
    t,
  } = useTranslation(['admin', 'common']);

  const [updateVoterInfo, { loading }] = useMutation<VoterInfoData, VoterInfo>(
    updateVoterInfoQuery,
    {
      refetchQueries: ['electionGroup'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        onSubmit();
      },
    }
  );

  const activeElections = electionGroup.elections.filter((e) => e.active);

  const initialValues: VoterInfoFormFieldPros =
    buildInitialValues(electionGroup);
  const { elections } = initialValues;

  if (activeElections.length === 0) {
    return <p>{t('election.noActiveElections', { ns: 'common' })}</p>;
  }
  return (
    <Form
      initialValues={initialValues}
      keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      onSubmit={(values: VoterInfoFormFieldPros) => {
        if (JSON.stringify(values) === JSON.stringify(initialValues)) {
          // Only call API with changes.
          onSubmit();
          return;
        }
        updateVoterInfo({
          variables: { ...buildPayload(values) },
        });
      }}
      validate={(values: VoterInfoFormFieldPros) =>
        validate(values, t, language, activeElections)
      }
    >
      {(formProps: FormRenderProps) => {
        const {
          handleSubmit,
          submitting,
          values,
          errors,
          valid,
          visited,
          touched,
        } = formProps;
        return (
          <form onSubmit={handleSubmit}>
            <PageSubSection header={t('election.election', { ns: 'common' })}>
              <Text size="large">{electionGroup.name[language]}</Text>
            </PageSubSection>

            <PageSubSection header={t('info.voterInfo.mandatePeriod')}>
              {elections.length > 1 && (
                <FormField>
                  <Field
                    name="hasMultipleMandateTimes"
                    component={RadioButtonGroup as any}
                    key="mandatefields"
                    options={[
                      {
                        id: 'single-mandate',
                        value: false,
                        label: t('info.voterInfo.mandatePeriodShared'),
                      },
                      {
                        id: 'multiple-mandate',
                        value: true,
                        label: t('info.voterInfo.mandatePeriodMultiple'),
                      },
                    ]}
                  />
                </FormField>
              )}

              {values.hasMultipleMandateTimes ? (
                <Table marginTop="2rem">
                  <TableHeader>
                    <TableHeaderRow>
                      <TableHeaderCell>
                        {t('election.group', { ns: 'common' })}
                      </TableHeaderCell>
                      <TableHeaderCell>
                        {t('info.voterInfo.fromDate')}
                      </TableHeaderCell>
                      <TableHeaderCell>
                        {t('info.voterInfo.toDate')}
                      </TableHeaderCell>
                    </TableHeaderRow>
                  </TableHeader>
                  <FieldArray
                    name="elections"
                    render={({ fields }) => (
                      <TableBody>
                        {fields.map((election, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Text>
                                {activeElections[index].name[language]}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <FormField inline noTopMargin>
                                <Field
                                  name={`${election}.mandatePeriodStart`}
                                  component={DateInputRF as any}
                                  small
                                />
                              </FormField>
                            </TableCell>
                            <TableCell>
                              <FormField inline noTopMargin>
                                <Field
                                  name={`${election}.mandatePeriodEnd`}
                                  component={DateInputRF as any}
                                  small
                                />
                              </FormField>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  />
                </Table>
              ) : (
                <FormFieldGroup>
                  <FormField inline noTopMargin>
                    <Field
                      name="elections[0].mandatePeriodStart"
                      component={DateInputRF as any}
                      label={t('info.voterInfo.fromDate')}
                    />
                  </FormField>
                  <FormField inline noTopMargin>
                    <Field
                      name="elections[0].mandatePeriodEnd"
                      component={DateInputRF as any}
                      label={t('info.voterInfo.toDate')}
                    />
                  </FormField>
                </FormFieldGroup>
              )}

              {errors && errors.formErrors
                ? errors.formErrors.mandateDates.map((error: any) => {
                    const { index } = error;
                    if (
                      visited &&
                      (visited[`elections[${index}].mandatePeriodStart`] ||
                        visited[`elections[${index}].mandatePeriodEnd`])
                    ) {
                      return <FormErrorMsg key={index} msg={error.msg} />;
                    }
                    return null;
                  })
                : null}
            </PageSubSection>

            <PageSubSection header={t('info.voterInfo.contactInfo')}>
              {elections.length > 1 && (
                <FormField>
                  <Field
                    name="hasMultipleContactInfo"
                    component={RadioButtonGroup as any}
                    options={[
                      {
                        id: 'single-contact',
                        value: false,
                        label: t('info.voterInfo.contactInfoShared'),
                      },
                      {
                        id: 'multiple-contact',
                        value: true,
                        label: t('info.voterInfo.contactInfoMultiple'),
                      },
                    ]}
                  />
                </FormField>
              )}
              {values.hasMultipleContactInfo ? (
                <Table>
                  <TableHeader>
                    <TableHeaderRow>
                      <TableHeaderCell>
                        {t('election.group', { ns: 'common' })}
                      </TableHeaderCell>
                      <TableHeaderCell>
                        {t('info.voterInfo.contactInfo')}
                      </TableHeaderCell>
                    </TableHeaderRow>
                  </TableHeader>
                  <FieldArray
                    name="elections"
                    render={({ fields }) => (
                      <TableBody>
                        {fields.map((election, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Text>
                                {activeElections[index].name[language]}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <FormField noTopMargin>
                                <Field
                                  name={`${election}.contact`}
                                  component={TextInput}
                                  placeholder={t('general.email', {
                                    ns: 'common',
                                  })}
                                  small
                                />
                              </FormField>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  />
                </Table>
              ) : (
                <FormField>
                  <Field
                    name="elections[0].contact"
                    component={TextInput}
                    placeholder={t('general.email', { ns: 'common' })}
                  />
                </FormField>
              )}
              {errors && errors.formErrors
                ? errors.formErrors.contact.map((error: any) => {
                    const { msg, index } = error;
                    if (touched && touched[`elections[${index}].contact`]) {
                      return <FormErrorMsg key={index} msg={msg} />;
                    }
                    return null;
                  })
                : null}
            </PageSubSection>

            <PageSubSection header={t('info.voterInfo.infoUrl')}>
              {elections.length > 1 && (
                <FormField>
                  <Field
                    name="hasMultipleInfoUrls"
                    component={RadioButtonGroup as any}
                    options={[
                      {
                        id: 'single-infourl',
                        value: false,
                        label: t('info.voterInfo.infoUrlShared'),
                      },
                      {
                        id: 'multiple-infourl',
                        value: true,
                        label: t('info.voterInfo.infoUrlMultiple'),
                      },
                    ]}
                  />
                </FormField>
              )}
              {values.hasMultipleInfoUrls ? (
                <Table>
                  <TableHeader>
                    <TableHeaderRow>
                      <TableHeaderCell>
                        {t('election.group', { ns: 'common' })}
                      </TableHeaderCell>
                      <TableHeaderCell>
                        {t('info.voterInfo.infoUrl')}
                      </TableHeaderCell>
                    </TableHeaderRow>
                  </TableHeader>
                  <FieldArray
                    name="elections"
                    render={({ fields }) => (
                      <TableBody>
                        {fields.map((election, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Text>
                                {activeElections[index].name[language]}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <FormField noTopMargin>
                                <Field
                                  name={`${election}.informationUrl`}
                                  component={TextInput}
                                  placeholder={t('general.webpage', {
                                    ns: 'common',
                                  })}
                                  small
                                />
                              </FormField>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  />
                </Table>
              ) : (
                <FormField>
                  <Field
                    name="elections[0].informationUrl"
                    component={TextInput}
                    placeholder={t('general.webpage', { ns: 'common' })}
                    id="infourl-single"
                  />
                </FormField>
              )}
              {errors && errors.formErrors
                ? errors.formErrors.informationUrl.map((error: any) => {
                    const { index } = error;
                    if (
                      touched &&
                      touched[`elections[${index}].informationUrl`]
                    ) {
                      return <FormErrorMsg key={index} msg={error.msg} />;
                    }
                    return null;
                  })
                : null}
            </PageSubSection>

            <FormButtons
              saveAction={handleSubmit}
              closeAction={closeAction}
              submitDisabled={!valid || submitting || loading}
              submitting={loading}
            />
          </form>
        );
      }}
    </Form>
  );
}
