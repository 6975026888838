import React, { ErrorInfo } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  wrapper: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  rawMsg: {
    border: '2px',
    borderStyle: 'solid',
    borderColor: 'grey',
    minWidth: '70%',
    margin: '2rem 10rem 2rem 3rem',
    padding: '1rem 1rem 1rem 1rem',
  },
});

interface Props {
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

function ErrorPage({
  error,
  errorInfo,
}: {
  error: Error | undefined;
  errorInfo: ErrorInfo | undefined;
}) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <h1>Something went wrong.</h1>
      <div className={classes.rawMsg}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </div>
      <div className={classes.rawMsg}>
        <pre>{JSON.stringify(errorInfo, null, 2)}</pre>
      </div>
    </div>
  );
}

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
    error: undefined,
    errorInfo: undefined,
  };

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback page.
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Save the errors.
    this.setState({ error: error, errorInfo: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // Render fallback page.
      return (
        <ErrorPage error={this.state.error} errorInfo={this.state.errorInfo} />
      );
    }
    return this.props.children;
  }
}
