import { useTranslation } from 'react-i18next';

import { PageSubSection } from 'components/page';
import Text from 'components/text';
import { IElectionGroupRole } from 'interfaces';

interface AdminRolesValuesProps {
  roles: IElectionGroupRole[];
}

export default function AdminRolesValues({ roles }: AdminRolesValuesProps) {
  const adminRoles = roles.filter((role) => role.name === 'admin');

  const { t } = useTranslation('admin');

  const adminRoleNames = adminRoles.map((role) => {
    switch (role.principal.__typename) {
      case 'PersonPrincipal':
        return role.principal.person.displayName;
      case 'PersonIdentifierPrincipal':
        return role.principal.idValue;
      case 'GroupPrincipal':
        return role.principal.group.name;
      default:
        return null;
    }
  });

  return (
    <PageSubSection header={t('info.roles.electionAdmins')}>
      <Text>{adminRoleNames.join(', ')}</Text>
    </PageSubSection>
  );
}
