import { useEffect, useRef, useState } from 'react';

import { Election, Candidate } from 'interfaces';
import { getCandidateArray } from 'utils/helpers';

import { BallotStep } from './utils';
import PrefElecBallot from './components/PrefElecBallot';
import PrefElecReview from './components/PrefElecReview';
import { useTranslation } from 'react-i18next';

function moveArrayItem(arr: any[], oldIndex: number, newIndex: number) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    /* eslint-disable no-plusplus */
    while (k--) {
      /* eslint-enable no-plusplus */
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

interface IProps {
  election: Election;
  ballotStep: BallotStep;
  onProceedToReview: () => void;
  onGoBackToSelectVoterGroup: () => void;
  onGoBackToBallot: () => void;
  onSubmitVote: (ballotData: object) => void;
  isSubmittingVote: boolean;
}

export default function PrefElecVote(props: IProps) {
  const {
    ballotStep,
    election,
    onProceedToReview,
    onSubmitVote,
    isSubmittingVote,
    onGoBackToBallot,
    onGoBackToSelectVoterGroup,
  } = props;

  const didMountRef = useRef(false);
  const candidatesRef = useRef(getCandidateArray(election.lists[0].candidates));
  const [selectedCandidates, setSelectedCandidates] = useState<Candidate[]>([]);
  const [isBlankVote, setIsBlankVote] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const { t } = useTranslation(['voting', 'common']);

  useEffect(() => {
    // Handles blank voting. We need to update the "isBlankVote" state
    // before we proceed to vote review. This effect will run after
    // the "isBlankVote" is saved.
    if (didMountRef.current) {
      onProceedToReview();
    } else {
      didMountRef.current = true;
    }
  }, [isBlankVote, onProceedToReview]);

  useEffect(() => {
    if (
      election.meta.ballotRules.votes === 'nr_of_seats' &&
      selectedCandidates.length > election.meta.candidateRules.seats
    ) {
      setErrorMsg(
        t('errors.toManyCandidatesSelected', {
          nrValid: election.meta.candidateRules.seats,
          nrSelected: selectedCandidates.length,
        })
      );
    } else if (
      election.meta.ballotRules.votes === 'nr_of_seats_and_subs' &&
      selectedCandidates.length >
        election.meta.candidateRules.seats +
          election.meta.candidateRules.substitutes
    ) {
      setErrorMsg(
        t('errors.toManyCandidatesSelected', {
          nrValid:
            election.meta.candidateRules.seats +
            election.meta.candidateRules.substitutes,
          nrSelected: selectedCandidates.length,
        })
      );
    } else if (
      typeof election.meta.ballotRules.votes === 'number' &&
      selectedCandidates.length > election.meta.ballotRules.votes
    ) {
      setErrorMsg(
        t('errors.toManyCandidatesSelected', {
          nrValid: election.meta.ballotRules.votes,
          nrSelected: selectedCandidates.length,
        })
      );
    } else {
      setErrorMsg(undefined);
    }
  }, [selectedCandidates, election, t]);

  const handleAddCandidate = (candidate: Candidate) => {
    setSelectedCandidates(selectedCandidates.concat([candidate]));
  };

  const handleRemoveCandidate = (candidate: Candidate) => {
    setSelectedCandidates(
      selectedCandidates.filter((c: Candidate) => c !== candidate)
    );
  };

  const handleMoveCandidate = (oldIndex: number, newIndex: number) => {
    const emptyArray: Candidate[] = [];
    const arrayCopy: Candidate[] = emptyArray.concat(selectedCandidates);
    moveArrayItem(arrayCopy, oldIndex, newIndex);
    setSelectedCandidates(arrayCopy);
  };

  const handleResetBallot = () => {
    setSelectedCandidates([]);
  };

  const handleBlankVoteAndProceedToReview = () => {
    // We only trigger the state update here.
    // The useEffect above handles redirect after the state is updated.
    setIsBlankVote(true);
  };

  const handleProceedToReview = () => {
    if (isBlankVote) {
      // If this function is called, the voter is trying to add a regular vote.
      // If isBankVote is true, we need to set it to false before we proceed to
      // review. The use effect above handles the redirect.
      setIsBlankVote(false);
    } else {
      onProceedToReview();
    }
  };

  const handleSubmitVote = () => {
    onSubmitVote({
      voteType: 'prefElecVote',
      isBlankVote,
      rankedCandidateIds: isBlankVote
        ? []
        : selectedCandidates.map((c) => c.id),
    });
  };

  const unselectedCandidates = candidatesRef.current.filter(
    (c) => selectedCandidates.indexOf(c) === -1
  );

  const isReviewButtonEnabled = () => {
    if (
      election.meta.ballotRules.votes === 'all' &&
      selectedCandidates.length > 0
    ) {
      return true;
    } else if (
      typeof election.meta.ballotRules.votes === 'number' &&
      selectedCandidates.length > 0 &&
      selectedCandidates.length <= election.meta.ballotRules.votes
    ) {
      return true;
    } else if (
      election.meta.ballotRules.votes === 'nr_of_seats' &&
      selectedCandidates.length > 0 &&
      selectedCandidates.length <= election.meta.candidateRules.seats
    ) {
      return true;
    } else if (
      election.meta.ballotRules.votes === 'nr_of_seats_and_subs' &&
      selectedCandidates.length > 0 &&
      selectedCandidates.length <=
        election.meta.candidateRules.seats +
          election.meta.candidateRules.substitutes
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {ballotStep === BallotStep.FillOutBallot && (
        <PrefElecBallot
          selectedCandidates={selectedCandidates}
          unselectedCandidates={unselectedCandidates}
          election={election}
          errorMsg={errorMsg}
          onAddCandidate={handleAddCandidate}
          onRemoveCandidate={handleRemoveCandidate}
          onMoveCandidate={handleMoveCandidate}
          onResetBallot={handleResetBallot}
          reviewBallotEnabled={isReviewButtonEnabled()}
          onGoBackToSelectVoterGroup={onGoBackToSelectVoterGroup}
          onBlankVote={handleBlankVoteAndProceedToReview}
          onReviewBallot={handleProceedToReview}
        />
      )}
      {ballotStep === BallotStep.ReviewBallot && (
        <PrefElecReview
          selectedCandidates={selectedCandidates}
          isBlankVote={isBlankVote}
          onGoBackToBallot={onGoBackToBallot}
          onSubmitVote={handleSubmitVote}
          isSubmittingVote={isSubmittingVote}
        />
      )}
    </>
  );
}
