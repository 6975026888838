import { ReactNode } from 'react';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import ErrorBoundary from 'components/errorBoundary';

interface AlertProps {
  children: ReactNode;
  header?: string;
  onClose?: () => void;
  severity: 'success' | 'info' | 'warning' | 'error';
}

export default function Alert({
  children,
  header,
  onClose,
  severity,
}: AlertProps) {
  return (
    <ErrorBoundary>
      <MuiAlert
        severity={severity}
        sx={{ fontSize: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}
        onClose={onClose}
      >
        {header && <AlertTitle sx={{ fontSize: '2rem' }}>{header}</AlertTitle>}
        {children}
      </MuiAlert>
    </ErrorBoundary>
  );
}
