import {
  ActiveComponentProps,
  InactiveComponentProps,
  ISettingsSectionContents,
} from 'components/page/SettingsSection';

import AdminRolesValues from './AdminRolesValues';
import AdminRolesForm from './AdminRolesForm';

function ActiveComponent({ closeAction, electionGroup }: ActiveComponentProps) {
  return <AdminRolesForm electionGroup={electionGroup} onClose={closeAction} />;
}

function InactiveComponent({
  electionGroup: { roles },
}: InactiveComponentProps) {
  return <AdminRolesValues roles={roles} />;
}

const AdminRolesSettingsSection: ISettingsSectionContents = {
  sectionName: 'adminRolesSettings',
  activeComponent: ActiveComponent,
  inactiveComponent: InactiveComponent,
  header: 'info.roles.header',
  description: 'info.roles.desc',
};

export default AdminRolesSettingsSection;
