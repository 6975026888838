import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  logoBar: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: theme.appMaxWidth,
    padding: `0 ${theme.horizontalPadding}`,
    [theme.breakpoints.mdQuery]: {
      padding: `0 ${theme.horizontalMdPadding}`,
    },
  },
  logoBarWrapper: {
    backgroundColor: theme.headerMainAreaColor,
  },
  logo: {
    marginTop: '5rem',
    marginBottom: '1rem',
    maxHeight: '7rem',
    maxWidth: '100%',
    backgroundSize: 'contain',
  },
}));

export default function NHHLogoBar() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const path = '/nhh/logo.png';

  return (
    <div className={classes.logoBarWrapper}>
      <div className={classes.logoBar}>
        <a href="https://nhh.no">
          <img className={classes.logo} src={path} alt="NHH logo" />
        </a>
      </div>
    </div>
  );
}
