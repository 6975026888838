import { useTranslation } from 'react-i18next';

import { InfoList, InfoListItem } from 'components/infolist';
import { InactiveComponentProps } from 'components/page/SettingsSection';
import Text from 'components/text';

export default function BaseElectionSettingsValues({
  electionGroup,
  electionGroup: { elections },
}: InactiveComponentProps) {
  const { t, i18n } = useTranslation(['admin', 'common']);
  const activeElections = elections.filter((e) => e.active);

  if (electionGroup.meta.candidateType === 'party_list') {
    const election = elections[0];
    return (
      <InfoList>
        {activeElections.length === 0 && (
          <InfoListItem>
            {t('election.noActiveElections', { ns: 'common' })}
          </InfoListItem>
        )}
        <InfoListItem key={1}>
          {election.name[i18n.language]}:{' '}
          <Text bold inline>
            {election.meta.candidateRules.seats}&nbsp;
            {t('info.baseSettings.candidatesShort')}
          </Text>
        </InfoListItem>
      </InfoList>
    );
  }

  return (
    <InfoList>
      {activeElections.length === 0 && (
        <InfoListItem>
          {t('election.noActiveElections', { ns: 'common' })}
        </InfoListItem>
      )}
      {activeElections.map((election: any, index: any) => {
        return (
          <InfoListItem key={index}>
            {election.name[i18n.language]}:{' '}
            <Text bold inline>
              {election.meta.candidateRules.seats}&nbsp;
              {t('info.baseSettings.candidatesShort')},&nbsp;
              {election.meta.candidateRules.substitutes}&nbsp;
              {t('info.baseSettings.coCandidatesShort')}
            </Text>
          </InfoListItem>
        );
      })}
      {electionGroup.hasGenderQuota && (
        <InfoListItem smallText>
          {t('info.baseSettings.hasGenderQuota')}
        </InfoListItem>
      )}
    </InfoList>
  );
}
