import {
  ActiveComponentProps,
  InactiveComponentProps,
  ISettingsSectionContents,
} from 'components/page/SettingsSection';

import ElectionNameValues from './ElectionNameValues';
import ElectionNameForm from './ElectionNameForm';

function ActiveComponent({
  closeAction,
  electionGroup,
  submitAction,
}: ActiveComponentProps) {
  return (
    <ElectionNameForm
      electionGroup={electionGroup}
      onSubmit={submitAction}
      closeAction={closeAction}
    />
  );
}

function InactiveComponent({ electionGroup }: InactiveComponentProps) {
  return <ElectionNameValues electionGroup={electionGroup} />;
}

const ElectionNameSettingsSection: ISettingsSectionContents = {
  sectionName: 'ElectionName',
  activeComponent: ActiveComponent,
  inactiveComponent: InactiveComponent,
  header: 'info.electionName.header',
  description: 'info.electionName.desc',
};

export default ElectionNameSettingsSection;
