import React from 'react';
import { useTranslation } from 'react-i18next';

import { Time, Date } from 'components/i18n';
import { InfoList, InfoListItem } from 'components/infolist';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/table';
import Text from 'components/text';
import { ElectionGroup, Election } from 'interfaces';
import { allEqualForAttrs } from 'utils';

interface VotingPeriodProps {
  elections: Election[];
}

function MultipleVotingPeriods({ elections }: VotingPeriodProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation(['admin', 'common']);

  const makeTableRow = (
    votingGroupName: React.ReactNode,
    startDateTime: string,
    endDateTime: string,
    key = 0
  ) => (
    <TableRow key={key}>
      <TableCell>
        <Text>{votingGroupName}</Text>
      </TableCell>
      <TableCell>
        <Text>
          <Date dateTime={startDateTime} longDate />
        </Text>
        <Text size="small">
          <Time dateTime={startDateTime} />
        </Text>
      </TableCell>
      <TableCell>
        <Text>
          <Date dateTime={endDateTime} longDate />
        </Text>
        <Text size="small">
          <Time dateTime={endDateTime} />
        </Text>
      </TableCell>
    </TableRow>
  );

  const tableRows =
    elections.length > 1 && allEqualForAttrs(elections, ['start', 'end'])
      ? [
          makeTableRow(
            <em>{t('info.votingPeriod.allVotingGroups')}</em>,
            elections[0].start,
            elections[0].end
          ),
        ]
      : elections.map((e: any, index: any) =>
          makeTableRow(e.name[language], e.start, e.end, index)
        );

  return (
    <Table>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>
            {t('election.voterGroup', { ns: 'common' })}
          </TableHeaderCell>
          <TableHeaderCell>
            {t('info.votingPeriod.electionOpens')}
          </TableHeaderCell>
          <TableHeaderCell>
            {t('info.votingPeriod.electionCloses')}
          </TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody>{tableRows}</TableBody>
    </Table>
  );
}

function SingleVotingPeriod({ elections }: VotingPeriodProps) {
  const { t } = useTranslation('admin');

  return (
    <InfoList>
      <InfoListItem>
        {t('info.votingPeriod.electionOpens')}:&nbsp;
        <Text bold inline>
          <Date dateTime={elections[0].start} longDate />{' '}
          <Time dateTime={elections[0].start} />
        </Text>
      </InfoListItem>
      <InfoListItem>
        {t('info.votingPeriod.electionCloses')}:&nbsp;
        <Text bold inline>
          <Date dateTime={elections[0].end} longDate />{' '}
          <Time dateTime={elections[0].end} />
        </Text>
      </InfoListItem>
    </InfoList>
  );
}

function NoActiveElections() {
  const { t } = useTranslation('common');
  return <p>{t('election.noActiveElections')}</p>;
}

function getVotingInfoComponent(grpType: string, elections: Election[]) {
  if (elections.length === 0) {
    return NoActiveElections;
  } else if (grpType === 'multiple_elections') {
    return MultipleVotingPeriods;
  }
  return SingleVotingPeriod;
}

interface VotingPeriodValuesProps {
  electionGroup: ElectionGroup;
  activeElections: Election[];
}

function VotingPeriodValues({
  electionGroup,
  activeElections,
}: VotingPeriodValuesProps) {
  const VotingTimes = getVotingInfoComponent(
    electionGroup.type,
    activeElections
  );
  return <VotingTimes elections={activeElections} />;
}

export default VotingPeriodValues;
