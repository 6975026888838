const white = '#FFFFFF';

// const nmbuGreen = '#009a81';
const nmbuBlack = '#282828';
const nmbuGrey = '#e6e6e6';
const nmbuLightGreen = '#d1e8df';

const NMBUTheme = {
  footerBackgroundColor: nmbuLightGreen,
  footerTextColor: nmbuBlack,
  headerDescColor: nmbuBlack,
  headerLogoBarColor: nmbuBlack,
  headerMainAreaColor: nmbuGrey,
  loginPageBgColor: white,
  dropDownDesktopMenuColor: nmbuBlack,
  linkColor: nmbuBlack,
  navMenuTextColor: 'black',
};

export default NMBUTheme;
