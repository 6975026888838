import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from 'components/alerts';
import Button, { ButtonContainer } from 'components/button';
import Icon from 'components/icon';
import { PageSection } from 'components/page';
import { ScreenSizeConsumer } from 'providers/ScreenSize';
import { Election, Candidate } from 'interfaces';

import BallotButtons from './BallotButtons';
import CandidateButtonBar from './CandidateButtonBar';
import {
  CandidateInfo,
  CandidateList,
  CandidateListItem,
  DownArrow,
  ListItemDesktopButtons,
  RemoveButton,
  ToggleSelectIcon,
  UpArrow,
} from './CandidateList';
import ElectionInfo from './electionInfo';
import HelpSubSection from './HelpSubSection';

interface IProps {
  selectedCandidates: Candidate[];
  unselectedCandidates: Candidate[];
  election: Election;
  errorMsg?: string;
  onAddCandidate: (c: Candidate) => void;
  onRemoveCandidate: (c: Candidate) => void;
  onMoveCandidate: (oldIndex: number, newIndex: number) => void;
  onResetBallot: () => void;
  onBlankVote: () => void;
  reviewBallotEnabled: boolean;
  onGoBackToSelectVoterGroup: () => void;
  onReviewBallot: () => void;
}

export default function PrefElecBallot(props: IProps) {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const { t } = useTranslation(['voting', 'common']);
  const {
    selectedCandidates,
    unselectedCandidates,
    election,
    errorMsg,
    onAddCandidate,
    onRemoveCandidate,
    onMoveCandidate,
    onResetBallot,
    onBlankVote,
    reviewBallotEnabled,
    onGoBackToSelectVoterGroup,
    onReviewBallot,
  } = props;

  const selectCandidate = (index: number) => {
    setActiveIndex(index);
  };

  const deselectCandidate = () => {
    setActiveIndex(-1);
  };

  const promoteSelectedCandidate = () => {
    onMoveCandidate(activeIndex, activeIndex - 1);
    setActiveIndex(activeIndex - 1);
  };

  const demoteSelectedCandidate = () => {
    onMoveCandidate(activeIndex, activeIndex + 1);
    setActiveIndex(activeIndex + 1);
  };

  const removeCandidate = () => {
    const candidate = selectedCandidates[activeIndex];
    onRemoveCandidate(candidate);
    setActiveIndex(-1);
  };

  const mobileResetButton = (
    <ScreenSizeConsumer>
      {({ screenSize }) =>
        (screenSize === 'mobile' || screenSize === 'sm') && (
          <ButtonContainer>
            <Button
              text={t('voter.resetPrefElecBallot')}
              action={onResetBallot}
              disabled={selectedCandidates.length === 0}
              secondary
              fillWidth={screenSize === 'mobile'}
              centerContent={screenSize === 'mobile'}
            />
          </ButtonContainer>
        )
      }
    </ScreenSizeConsumer>
  );

  let helpDesc = t('prefVoting.helpDesc');

  if (election.meta.ballotRules.votes === 'nr_of_seats') {
    helpDesc = t('prefVoting.helpDescLimited', {
      nr: election.meta.candidateRules.seats,
    });
  } else if (election.meta.ballotRules.votes === 'nr_of_seats_and_subs') {
    helpDesc = t('prefVoting.helpDescLimited', {
      nr:
        election.meta.candidateRules.seats +
        election.meta.candidateRules.substitutes,
    });
  } else if (typeof election.meta.ballotRules.votes === 'number') {
    helpDesc = t('prefVoting.helpDescLimited', {
      nr: election.meta.ballotRules.votes,
    });
  }

  let helpTextTags: string[] = [];

  if (election.meta.ballotRules.votes === 'all') {
    helpTextTags.push('voter.prefElecNrOfCandidates');
  } else {
    helpTextTags.push('voter.prefElecNrOfCandidatesLimited');
  }

  if (election.meta.countingRules.method === 'positional_voting') {
    helpTextTags.push('voter.prefElecRankPositionalVoting');
  } else {
    helpTextTags.push('voter.prefElecRankCandidates');
  }

  helpTextTags = helpTextTags.concat([
    'voter.prefElecOnlySelectedGetVote',
    'voter.canVoteBlank',
  ]);

  return (
    <ScreenSizeConsumer>
      {({ screenSize }) => (
        <PageSection noBorder>
          <ElectionInfo election={election} />
          <HelpSubSection
            header={t('voter.chooseCandidates')}
            desc={helpDesc}
            helpTextTags={helpTextTags}
          />
          {mobileResetButton}
          {errorMsg && <Alert type="error">{errorMsg}</Alert>}
          <CandidateList>
            {selectedCandidates.map((c, index) => (
              <CandidateListItem key={`selected-${index}`}>
                <Icon
                  type="rankCircle"
                  custom={{
                    nr: index + 1,
                    small: screenSize !== 'mobile' && screenSize !== 'sm',
                  }}
                />
                <CandidateInfo candidate={c} infoUrl />
                {screenSize === 'mobile' || screenSize === 'sm' ? (
                  <ToggleSelectIcon
                    flexRight
                    selected={index === activeIndex}
                    action={() =>
                      activeIndex === index
                        ? deselectCandidate()
                        : selectCandidate(index)
                    }
                  />
                ) : (
                  <ListItemDesktopButtons>
                    {index !== 0 ? (
                      <UpArrow
                        onClick={() => onMoveCandidate(index, index - 1)}
                        title={t('prefElec.ballot.promoteCandidate', {
                          candidate: c.name,
                          index: index,
                        })}
                      />
                    ) : null}
                    {index < selectedCandidates.length - 1 ? (
                      <DownArrow
                        onClick={() => onMoveCandidate(index, index + 1)}
                        title={t('prefElec.ballot.demoteCandidate', {
                          candidate: c.name,
                          index: index + 2,
                        })}
                      />
                    ) : null}
                    <RemoveButton
                      onClick={() => onRemoveCandidate(c)}
                      title={t('prefElec.ballot.removeCandidate', {
                        candidate: c.name,
                      })}
                    />
                  </ListItemDesktopButtons>
                )}
              </CandidateListItem>
            ))}
            {unselectedCandidates.map((c, index) => (
              <CandidateListItem key={`unselected-${index}`}>
                <Icon
                  type="addCircle"
                  title={t('prefElec.ballot.addCandidate', {
                    candidate: c.name,
                  })}
                  custom={
                    screenSize !== 'mobile' && screenSize !== 'sm'
                      ? 'small'
                      : false
                  }
                  onClick={() => onAddCandidate(c)}
                />
                <CandidateInfo candidate={c} infoUrl />
              </CandidateListItem>
            ))}
          </CandidateList>

          {(screenSize === 'mobile' || screenSize === 'sm') &&
          activeIndex !== -1 ? (
            <CandidateButtonBar
              upAction={promoteSelectedCandidate}
              downAction={demoteSelectedCandidate}
              removeAction={removeCandidate}
              removeText={t('general.remove')}
              upDisabled={activeIndex === 0}
              downDisabled={activeIndex === selectedCandidates.length - 1}
            />
          ) : null}
          <BallotButtons
            onGoBackToSelectVoterGroup={onGoBackToSelectVoterGroup}
            onBlankVote={onBlankVote}
            reviewBallotEnabled={reviewBallotEnabled}
            onReviewBallot={onReviewBallot}
          />
        </PageSection>
      )}
    </ScreenSizeConsumer>
  );
}
