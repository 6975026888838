

const OrgregTree = () => {
  return (
    // Denne skal fylles ut
    <div>Hellu</div>
  )
}

export default OrgregTree
