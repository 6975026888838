import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  logoBar: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: theme.appMaxWidth,
    padding: `0 ${theme.horizontalPadding}`,
    [theme.breakpoints.mdQuery]: {
      padding: `0 ${theme.horizontalMdPadding}`,
    },
  },
  logoBarWrapper: {
    backgroundColor: theme.headerMainAreaColor,
  },
  logo: {
    height: '6rem',
    marginTop: '2rem',
  },
}));

export default function NMBULogoBar() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const path = '/nmh/logo.svg';

  return (
    <div className={classes.logoBarWrapper}>
      <div className={classes.logoBar}>
        <a href="https://nmh.no">
          <img className={classes.logo} src={path} alt="NMH logo" />
        </a>
      </div>
    </div>
  );
}
