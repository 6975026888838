import {
  ActiveComponentProps,
  InactiveComponentProps,
  ISettingsSectionContents,
} from 'components/page/SettingsSection';

import BaseElectionSettingsValues from './BaseElectionSettingsValues';
import BaseElectionSettingsForm from './BaseElectionSettingsForm';

function ActiveComponent({
  closeAction,
  electionGroup,
  submitAction,
}: ActiveComponentProps) {
  if (
    electionGroup.type !== 'multiple_elections' &&
    electionGroup.meta.candidateType !== 'party_list'
  ) {
    return null;
  }

  return (
    <BaseElectionSettingsForm
      electionGroup={electionGroup}
      onSubmit={submitAction}
      closeAction={closeAction}
    />
  );
}

function InactiveComponent({ electionGroup }: InactiveComponentProps) {
  if (
    electionGroup.type !== 'multiple_elections' &&
    electionGroup.meta.candidateType !== 'party_list'
  ) {
    return null;
  }

  return <BaseElectionSettingsValues electionGroup={electionGroup} />;
}

const BaseElectionSettingsSection: ISettingsSectionContents = {
  sectionName: 'baseElectionSettings',
  activeComponent: ActiveComponent,
  inactiveComponent: InactiveComponent,
  header: 'info.baseSettings.header',
  description: 'info.baseSettings.desc',
};

export default BaseElectionSettingsSection;
