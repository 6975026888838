const white = '#FFFFFF';
const innGrey = '#262626';

const INNTheme = {
  headerMainAreaColor: innGrey,
  headerLogoBarColor: innGrey,
  headerDescColor: white,
  footerBackgroundColor: innGrey,
  footerTextColor: white,
  loginPageBgColor: white,
};

export default INNTheme;
