import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { NotFound, ErrorPageSection } from 'components/errors';
import Loading from 'components/loading';
import Page from 'components/page/Page';
import { PageSection } from 'components/page';
import { ActionButton } from 'components/button';
import Link from 'components/link';
import { electionGroupWithOrderedElections } from 'utils/processGraphQLData';
import { ElectionGroup, IRoleGrant, IElectionGroupRole } from 'interfaces';
import { ElectionGroupFields, ElectionFields } from 'fragments';

import ManageElectionsTable from './components/ManageElectionsTable';

const viewerElectionGroupsQuery = gql`
  ${ElectionGroupFields}
  ${ElectionFields}
  query ViewerElectionGroupsAndRoles($onlyAdmin: Boolean) {
    electionGroups(onlyAdmin: $onlyAdmin) {
      ...ElectionGroupFields
      elections {
        ...ElectionFields
        voteCount {
          selfAddedNotReviewed
          total
        }
      }
    }
    viewer {
      roles {
        ... on ElectionGroupRole {
          groupId
        }
      }
    }
  }
`;

export default function AdminFrontPage() {
  const { t } = useTranslation(['admin']);
  const { data, error, loading } = useQuery(viewerElectionGroupsQuery, {
    variables: { onlyAdmin: true },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorPageSection errorMessage={error.message} />;
  }
  if (data.electionGroup === null) {
    return <NotFound />;
  }

  /* Filter out election groups which the viewer does not have a role for */
  const electionGroupRoles =
    data.viewer.roles &&
    data.viewer.roles.filter((role: IRoleGrant) => {
      return role.__typename === 'ElectionGroupRole';
    });

  const manageableElectionGroupIds = electionGroupRoles.map(
    (role: IElectionGroupRole) => {
      return role.groupId;
    }
  );

  const manageableElectionGroups: ElectionGroup[] = data.electionGroups.filter(
    (electionGroup: ElectionGroup) => {
      return manageableElectionGroupIds.includes(electionGroup.id);
    }
  );

  const electionGroupsWithOrderedElections: ElectionGroup[] =
    manageableElectionGroups.map((electionGroup: ElectionGroup) =>
      electionGroupWithOrderedElections(electionGroup)
    );

  return (
    <Page header={t('frontpage.header')}>
      <PageSection noBorder noBtmPadding>
        <Link to="/admin/newElection">
          <ActionButton text={t('createNewElection.header')} />
        </Link>
      </PageSection>
      <PageSection noBorder header={<>{t('frontpage.manageableElections')}</>}>
        <ManageElectionsTable
          electionGroups={electionGroupsWithOrderedElections}
        />
      </PageSection>
    </Page>
  );
}
