import React from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonContainer } from 'components/button';
import theme from 'theme';
import { CircularProgress } from '@mui/material';

function SavingSpinner() {
  return (
    <CircularProgress
      size={'2.3rem'}
      sx={{
        position: 'relative',
        marginLeft: 10,
        marginRight: -2,
        display: 'inline-block',
        border: '3px solid rgba(255,255,255,.3)',
        borderRadius: '50%',
        borderTopColor: theme.colors.white,
        animationDuration: '0.8s',
        color: theme.colors.blueGreyish,
      }}
    />
  );
}

interface FormButtonsProps {
  cancelDisabled?: boolean;
  closeAction?: (id: any) => void;
  customButtonText?: React.ReactNode | string;
  customButtonTextSubmitting?: React.ReactNode | string;
  entityAction?: any;
  entityActionDisabled?: boolean;
  entityText?: React.ReactNode | string;
  entityDanger?: boolean;
  submitDisabled: boolean;
  saveAction: (submitValues: any) => void;
  submitting?: boolean;
}

export default function FormButtons(props: FormButtonsProps) {
  const {
    saveAction,
    closeAction,
    submitDisabled,
    submitting,
    entityAction,
    entityActionDisabled,
    entityDanger,
    entityText,
    customButtonText,
    customButtonTextSubmitting,
  } = props;
  const { t } = useTranslation();

  return (
    <ButtonContainer alignRight>
      {entityAction && entityText && (
        <Button
          text={entityText}
          action={entityAction}
          disabled={entityActionDisabled}
          dangerButton={entityDanger}
          secondary={!entityDanger}
        />
      )}
      {closeAction && (
        <Button
          text={t('general.cancel')}
          disabled={submitting}
          action={closeAction}
          secondary
        />
      )}
      <Button
        text={
          submitting ? (
            <>
              {customButtonTextSubmitting
                ? customButtonTextSubmitting
                : t('general.saving')}
              <SavingSpinner />
            </>
          ) : (
            <>{customButtonText ? customButtonText : t('general.save')}</>
          )
        }
        disabled={submitDisabled}
        action={saveAction}
      />
    </ButtonContainer>
  );
}

FormButtons.defaultProps = {
  entityActionDisabled: false,
  entityDanger: false,
};
