import { useTranslation } from 'react-i18next';

import { Date } from 'components/i18n';
import { InfoList, InfoListItem } from 'components/infolist';
import Link from 'components/link';
import { PageSubSection } from 'components/page';
import Text from 'components/text';
import { Election, ElectionGroup } from 'interfaces';
import { allEqualForAttrs } from 'utils';

function ValueNotSet() {
  const { t } = useTranslation('common');
  return <b>{t('general.notSet')}</b>;
}

function MandatePeriodSingle({ election }: { election: Election }) {
  const { t } = useTranslation('common');
  const startDate = election.mandatePeriodStart ? (
    <Date dateTime={election.mandatePeriodStart} longDate />
  ) : (
    <ValueNotSet />
  );
  const endDate = election.mandatePeriodEnd ? (
    <Date dateTime={election.mandatePeriodEnd} longDate />
  ) : (
    <ValueNotSet />
  );
  return (
    <span>
      {startDate}&nbsp;{t('general.to')}&nbsp;{endDate}
    </span>
  );
}

function MandatePeriodMultiple({
  elections,
  language,
}: {
  elections: Array<Election>;
  language: string;
}) {
  const { t } = useTranslation('common');
  return (
    <InfoList>
      {elections.map((election: any, index: any) => {
        return (
          <InfoListItem key={index} bulleted>
            {election.name[language]}:{' '}
            <Text bold inline>
              <Date dateTime={election.mandatePeriodStart} longDate />
              &nbsp;{t('general.to')}&nbsp;
              <Date dateTime={election.mandatePeriodEnd} longDate />
            </Text>
          </InfoListItem>
        );
      })}
    </InfoList>
  );
}

function ContactSingle({ election }: { election: Election }) {
  if (election.contact) {
    return (
      <Link mail to={election.contact}>
        {election.contact}
      </Link>
    );
  }
  return <ValueNotSet />;
}

function ContactMultiple({
  elections,
  language,
}: {
  elections: Array<Election>;
  language: string;
}) {
  return (
    <InfoList>
      {elections.map((election: any, index: any) => {
        const contact = election.contact ? (
          <Link mail to={election.contact}>
            {election.contact}
          </Link>
        ) : (
          <ValueNotSet />
        );
        return (
          <InfoListItem key={index} bulleted>
            {election.name[language]}: {contact}
          </InfoListItem>
        );
      })}
    </InfoList>
  );
}

function InformationUrlSingle({ election }: { election: Election }) {
  if (election.informationUrl) {
    return (
      <Link external to={election.informationUrl}>
        {election.informationUrl}
      </Link>
    );
  }
  return <ValueNotSet />;
}

function InformationUrlMultiple({
  elections,
  language,
}: {
  elections: Array<Election>;
  language: string;
}) {
  return (
    <InfoList>
      {elections.map((election: any, index: any) => {
        const informationUrl = election.informationUrl ? (
          <Link external to={election.informationUrl}>
            {election.informationUrl}
          </Link>
        ) : (
          <ValueNotSet />
        );
        return (
          <InfoListItem key={index} bulleted>
            {election.name[language]}: {informationUrl}
          </InfoListItem>
        );
      })}
    </InfoList>
  );
}

function VoterInfoValues({ electionGroup }: { electionGroup: ElectionGroup }) {
  const {
    i18n: { language },
    t,
  } = useTranslation(['admin', 'common']);
  const elections = electionGroup.elections.filter((e) => e.active);

  let mandatePeriodInfo = null;
  let contact = null;
  let informationUrl = null;

  if (elections.length === 0) {
    mandatePeriodInfo = (
      <p>{t('election.noActiveElections', { ns: 'common' })}</p>
    );
    contact = <p>{t('election.noActiveElections', { ns: 'common' })}</p>;
    informationUrl = <p>{t('election.noActiveElections', { ns: 'common' })}</p>;
  } else if (electionGroup.type === 'single_election') {
    mandatePeriodInfo = (
      <p>
        <MandatePeriodSingle election={elections[0]} />{' '}
      </p>
    );
    contact = (
      <p>
        <ContactSingle election={elections[0]} />
      </p>
    );
    informationUrl = (
      <p>
        <InformationUrlSingle election={elections[0]} />
      </p>
    );
  } else {
    // to not present the value as "shared" if there
    // is only one active election
    if (
      elections.length > 1 &&
      allEqualForAttrs(elections, ['mandatePeriodStart', 'mandatePeriodEnd'])
    ) {
      mandatePeriodInfo = (
        <p>
          {t('info.voterInfo.mandatePeriodShared')}:{' '}
          <Text bold inline>
            <MandatePeriodSingle election={elections[0]} />
          </Text>
        </p>
      );
    } else {
      mandatePeriodInfo = (
        <MandatePeriodMultiple elections={elections} language={language} />
      );
    }
    if (elections.length > 1 && allEqualForAttrs(elections, ['contact'])) {
      contact = (
        <p>
          {t('info.voterInfo.contactInfoShared')}:{' '}
          <ContactSingle election={elections[0]} />
        </p>
      );
    } else {
      contact = <ContactMultiple elections={elections} language={language} />;
    }
    if (
      elections.length > 1 &&
      allEqualForAttrs(elections, ['informationUrl'])
    ) {
      informationUrl = (
        <p>
          {t('info.voterInfo.infoUrlShared')}:{' '}
          <InformationUrlSingle election={elections[0]} />
        </p>
      );
    } else {
      informationUrl = (
        <InformationUrlMultiple elections={elections} language={language} />
      );
    }
  }

  return (
    <>
      <PageSubSection header={t('election.election', { ns: 'common' })}>
        <p>{electionGroup.name[language]}</p>
      </PageSubSection>

      <PageSubSection header={t('info.voterInfo.mandatePeriod')}>
        {mandatePeriodInfo}
      </PageSubSection>

      <PageSubSection header={t('info.voterInfo.contactInfo')}>
        {contact}
      </PageSubSection>

      <PageSubSection header={t('info.voterInfo.infoUrl')}>
        {informationUrl}
      </PageSubSection>
    </>
  );
}

export default VoterInfoValues;
