import { useTranslation } from 'react-i18next';
import { createUseStyles, useTheme } from 'react-jss';

import Link from 'components/link';
import { PageSection } from 'components/page';

const useStyles = createUseStyles((theme: any) => ({
  receiptTextBox: {
    ...theme.ingress,
    maxWidth: '63rem',
  },
}));

export default function Receipt() {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <PageSection noBorder>
      <div className={classes.receiptTextBox}>
        {t('voter.receiptThankYou')}
        <br />
        <br />
        {t('voter.receiptVotingAgainInfo')}
        <br />
        <br />
        <Link to="/">{t('voter.receiptGoToFrontpage')}</Link>{' '}
        {t('voter.receiptOr')}{' '}
        <Link to="/logout">{t('voter.receiptLogout')}</Link>.
      </div>
    </PageSection>
  );
}
