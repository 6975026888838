import theme from 'theme';

const nhhLightBlue = '#C4DFE0';
const nhhBlue = '#00395A';

const NHHTheme = {
  actionTextColor: nhhBlue,
  dropDownDesktopMenuColor: nhhBlue,
  dropDownArrorColor: nhhBlue,
  footerBackgroundColor: nhhLightBlue,
  footerTextColor: theme.colors.black,
  headerDescColor: nhhBlue,
  headerMainAreaColor: theme.colors.white,
  linkColor: nhhBlue,
  loginPageBgColor: nhhBlue,
};

export default NHHTheme;
