import React from 'react';
import { useTranslation } from 'react-i18next';
import { appTimezone } from 'appConfig';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz'
import { nb, enUS, nn } from 'date-fns/locale'

interface IProps {
  dateTime: Date | string;
}

const prefixes: { [id: string]: string } = {
  en: 'at',
  nb: 'kl',
  nn: 'kl',
};

const Time: React.FunctionComponent<IProps> = (props: IProps) => {
  const { i18n, t } = useTranslation();

  const localeMap = {
    en: enUS,
    nb: nb,
    nn: nn,
  };
  const selectedLocale = localeMap[i18n.language]

  if (!props.dateTime) {
    return <span>{t('election.valueNotSet')}</span>;
  }
  const zonedTime = utcToZonedTime(props.dateTime, appTimezone);
  const lang = i18n.language;
  const time = format(zonedTime, 'p', { locale: selectedLocale });
  const prefix: string = (prefixes[lang] !== undefined ? prefixes[lang] : 'kl')

  return <span>{`${prefix} ${time}`}</span>;
};

export default Time;
