/* eslint no-underscore-dangle: 0 */
import { gql, useMutation } from '@apollo/client';
import { TFunction, useTranslation } from 'react-i18next';
import { Form, Field, FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import Text from 'components/text';
import {
  DateInputRF,
  FormErrorMsg,
  FormField,
  FormFieldGroup,
  RadioButtonGroup,
  TimeInputRF,
} from 'components/form';

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/table';
import FormButtons from 'components/form/FormButtons';
import {
  Election,
  ElectionGroup,
  ElectionVotingPeriodInput,
  MutationResponse,
  NameFields,
} from 'interfaces';
import {
  ISODateTimeToTimeZoneAdjustedISODate,
  ISODateTimeToTimeZoneAdjustedTime,
  DateAndTimeToISODTWithTimeZonedOffset,
} from 'utils';

const updateVotingPeriodsQuery = gql`
  mutation UpdateVotingPeriods(
    $hasMultipleTimes: Boolean!
    $elections: [ElectionVotingPeriodInput]!
  ) {
    updateVotingPeriods(
      hasMultipleTimes: $hasMultipleTimes
      elections: $elections
    ) {
      ok
    }
  }
`;

// Mutation inputs
interface VotingPeriodsInput {
  hasMultipleTimes: boolean;
  elections: ElectionVotingPeriodInput[];
}

// Mutation return data
interface VotingPeriodsData {
  updateVotingPeriods: MutationResponse;
}

// Form values
interface FormElectionValues {
  id: string;
  name: NameFields;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}
interface VotingPeriodFormValues {
  elections: FormElectionValues[];
  hasMultipleTimes: boolean;
}

function buildInitialValues(elections: Election[]): VotingPeriodFormValues {
  const formElections = elections.map((e) => ({
    id: e.id,
    name: e.name,
    startDate: ISODateTimeToTimeZoneAdjustedISODate(e.start),
    startTime: ISODateTimeToTimeZoneAdjustedTime(e.start),
    endDate: ISODateTimeToTimeZoneAdjustedISODate(e.end),
    endTime: ISODateTimeToTimeZoneAdjustedTime(e.end),
  }));

  let hasMultipleTimes = false;
  if (elections.length > 1) {
    for (let i = 0; i < elections.length - 1; i += 1) {
      if (
        elections[i].start !== elections[i + 1].start ||
        elections[i].end !== elections[i + 1].end
      ) {
        hasMultipleTimes = true;
        break;
      }
    }
  }
  return { elections: formElections, hasMultipleTimes };
}

function buildSubmitPayload(
  values: VotingPeriodFormValues
): VotingPeriodsInput {
  return {
    elections: values.elections.map((e) => ({
      id: e.id,
      start: DateAndTimeToISODTWithTimeZonedOffset(e.startDate, e.startTime),
      end: DateAndTimeToISODTWithTimeZonedOffset(e.endDate, e.endTime),
    })),
    hasMultipleTimes: values.hasMultipleTimes,
  };
}

function SingleElectionForm({ disabled }: { disabled: boolean }) {
  const { t } = useTranslation('admin');
  return (
    <div>
      <FormFieldGroup>
        <FormField inline>
          <Field
            component={DateInputRF as any}
            disabled={disabled}
            label={t('info.votingPeriod.electionOpens')}
            name="elections[0].startDate"
          />
        </FormField>
        <FormField inline>
          <Field
            component={TimeInputRF as any}
            disabled={disabled}
            label="&nbsp;"
            name="elections[0].startTime"
          />
        </FormField>
      </FormFieldGroup>
      <FormFieldGroup>
        <FormField inline>
          <Field
            component={DateInputRF as any}
            disabled={disabled}
            label={t('info.votingPeriod.electionCloses')}
            name="elections[0].endDate"
          />
        </FormField>
        <FormField inline>
          <Field
            component={TimeInputRF as any}
            disabled={disabled}
            label="&nbsp;"
            name="elections[0].endTime"
          />
        </FormField>
      </FormFieldGroup>
    </div>
  );
}

function MultipleElectionsForm({
  disabled,
  elections,
  hasMultipleTimes,
}: {
  disabled: boolean;
  elections: FormElectionValues[];
  hasMultipleTimes: boolean;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation(['admin', 'common']);

  return (
    <>
      <FormField>
        <Field
          component={RadioButtonGroup as any}
          disabled={disabled}
          name="hasMultipleTimes"
          options={[
            {
              id: 'singleperiod',
              value: false,
              label: t('info.votingPeriod.singleVotingPeriod'),
            },
            {
              id: 'multipleperiods',
              value: true,
              label: t('info.votingPeriod.multipleVotingPeriods'),
            },
          ]}
        />
      </FormField>
      {hasMultipleTimes ? (
        <Table>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell>
                {t('election.group', { ns: 'common' })}
              </TableHeaderCell>
              <TableHeaderCell>
                {t('info.votingPeriod.electionOpens')}
              </TableHeaderCell>
              <TableHeaderCell>
                {t('info.votingPeriod.electionCloses')}
              </TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            <FieldArray name="elections">
              {({ fields }) =>
                fields.map((election, index) => (
                  <TableRow key={`${election}.id`}>
                    <TableCell>
                      <Text>{elections[index].name[language]}</Text>
                    </TableCell>
                    <TableCell>
                      <FormFieldGroup>
                        <FormField inline smallBottomMargin>
                          <Field
                            component={DateInputRF as any}
                            disabled={disabled}
                            name={`${election}.startDate`}
                            small
                          />
                        </FormField>
                        <FormField inline smallBottomMargin>
                          <Field
                            component={TimeInputRF as any}
                            disabled={disabled}
                            name={`${election}.startTime`}
                            small
                          />
                        </FormField>
                      </FormFieldGroup>
                    </TableCell>
                    <TableCell>
                      <FormFieldGroup>
                        <FormField inline smallBottomMargin>
                          <Field
                            component={DateInputRF as any}
                            disabled={disabled}
                            name={`${election}.endDate`}
                            small
                          />
                        </FormField>
                        <FormField inline smallBottomMargin>
                          <Field
                            component={TimeInputRF as any}
                            disabled={disabled}
                            name={`${election}.endTime`}
                            small
                          />
                        </FormField>
                      </FormFieldGroup>
                    </TableCell>
                  </TableRow>
                ))
              }
            </FieldArray>
          </TableBody>
        </Table>
      ) : (
        <SingleElectionForm disabled={disabled} />
      )}
    </>
  );
}

function determineFormType(grpType: string, elections: Election[]) {
  if (grpType === 'multiple_elections' && elections.length > 1) {
    return MultipleElectionsForm;
  }
  return SingleElectionForm;
}

function electionValuesSet(e: any) {
  return e.startDate && e.startTime && e.endDate && e.endTime;
}

function validate(
  values: VotingPeriodFormValues,
  t: TFunction,
  language: string
) {
  const errors: any = {};
  errors.elections = [];
  const formErrors: any[] = [];
  const { hasMultipleTimes, elections } = values;
  if (!hasMultipleTimes && electionValuesSet(elections[0])) {
    const { startDate, startTime, endDate, endTime } = elections[0];
    const startDateTime = startDate + startTime;
    const endDateTime = endDate + endTime;
    if (startDateTime >= endDateTime) {
      formErrors.push(t('formErrors.invalidDates', { ns: 'common' }));
      Object.assign(errors, {
        elections: [
          { startDate: true, startTime: true, endDate: true, endTime: true },
        ],
      });
    }
  } else {
    values.elections.forEach((election: FormElectionValues) => {
      let electionErrors;
      if (electionValuesSet(election)) {
        const { startTime, startDate, endTime, endDate } = election;
        const startDateTime = startDate + startTime;
        const endDateTime = endDate + endTime;
        if (startDateTime >= endDateTime) {
          formErrors.push(
            <span>
              {election.name[language]}:{' '}
              {t('formErrors.invalidDates', { ns: 'common' })}
            </span>
          );
          electionErrors = {
            startDate: true,
            startTime: true,
            endDate: true,
            endTime: true,
          };
        }
      }
      errors.elections.push(electionErrors);
    });
  }
  if (formErrors.length > 0) {
    errors._error = formErrors;
  }
  return errors;
}

interface VotingPeriodFormProps {
  closeAction: () => void;
  electionGroup: ElectionGroup;
  onSubmit: () => void;
}

export default function VotingPeriodForm(props: VotingPeriodFormProps) {
  const [updateVotingPeriods, { loading }] = useMutation<
    VotingPeriodsData,
    VotingPeriodsInput
  >(updateVotingPeriodsQuery, {
    refetchQueries: ['electionGroup'],
    awaitRefetchQueries: true,
  });

  const { closeAction, electionGroup, onSubmit } = props;
  const {
    t,
    i18n: { language },
  } = useTranslation(['admin', 'common']);

  const activeElections = electionGroup.elections.filter((e) => e.active);

  const initialValues = buildInitialValues(activeElections);
  const { elections } = initialValues;
  if (elections.length === 0) {
    return <p>{t('election.noActiveElections', { ns: 'common' })}</p>;
  }

  const PeriodForm = determineFormType(
    electionGroup.type,
    electionGroup.elections
  );

  return (
    <Form
      onSubmit={(values: VotingPeriodFormValues) => {
        if (JSON.stringify(values) === JSON.stringify(initialValues)) {
          // Only call API with changes.
          onSubmit();
          return;
        }
        updateVotingPeriods({
          variables: { ...buildSubmitPayload(values) },
          onCompleted: () => {
            onSubmit();
          },
        });
      }}
      keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      validate={(values: VotingPeriodFormValues) =>
        validate(values, t, language)
      }
      initialValues={initialValues}
    >
      {(formProps: FormRenderProps) => {
        const { handleSubmit, submitting, values, errors, valid } = formProps;
        // initialValues will not be injected on initial render
        if (values === undefined) {
          return null;
        }

        return (
          <form onSubmit={handleSubmit}>
            <PeriodForm
              elections={elections}
              hasMultipleTimes={values.hasMultipleTimes}
              disabled={submitting || loading}
            />
            {errors && errors._error && (
              <div>
                {errors._error.map((err: any, index: number) => {
                  return <FormErrorMsg key={index} msg={err} />;
                })}
              </div>
            )}
            <FormButtons
              saveAction={handleSubmit}
              closeAction={closeAction}
              submitDisabled={!valid || submitting || loading}
              submitting={submitting || loading}
            />
          </form>
        );
      }}
    </Form>
  );
}
