import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';

import { ElectionResult, ElectionList, Candidate } from 'interfaces';
import { H4 } from 'components/text';
import { PageExpandableSubSection } from 'components/page/PageSection';

const useStyles = createUseStyles((theme: any) => ({
  sectionLevel1: {
    marginBottom: '2rem',
  },
  sectionLevel2: {
    '&:not(:last-child)': {
      marginBottom: '1.5rem',
    },
  },
  candidatesList: {
    listStylePosition: 'inside',
  },
  substituteCandidatesList: {
    listStyle: 'none',
  },
  candidateListItem: {
    lineHeight: '1.5',
  },
  errorText: {
    color: theme.errorTextColor,
  },
  listResultContent: {
    marginLeft: '3rem',
    listStylePosition: 'inside',
  },
  listResultHeader: {
    fontSize: '2rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  listResultSubHeader: {
    fontSize: '1.7rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}));

interface ListElectionResultProps {
  result: any;
  electionLists: ElectionList[];
}

function ListElectionResultList({
  result,
  electionLists,
}: ListElectionResultProps) {
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles({ theme });

  const idToElectionList = Object.assign(
    {},
    ...electionLists.map((electionList) => ({
      [electionList.id]: electionList,
    }))
  );

  // Convert results to a list for sorting.

  const resultsArray = Object.entries(result).map(
    ([listId, listResult]: [list_id: string, list_result: any]) => {
      let newResult = Object.assign({}, listResult);
      newResult.id = listId;
      return newResult;
    }
  );
  resultsArray.sort((a: any, b: any) => b.mandates - a.mandates);

  return (
    <>
      {resultsArray.map((listResult: any) => {
        const electionList = idToElectionList[listResult.id];

        const idToCandidate = Object.assign(
          {},
          ...electionList.candidates.map((candidate: Candidate) => ({
            [candidate.id]: candidate,
          }))
        );

        const electedCandidates = listResult.sorted_candidates_with_votes.slice(
          0,
          listResult.mandates
        );

        let deputyCandidates = [];
        if (listResult.mandates > 0) {
          deputyCandidates = listResult.sorted_candidates_with_votes.slice(
            listResult.mandates
          );
        }

        return (
          <PageExpandableSubSection
            key={listResult.id}
            header={t('admin.listElec.result.listHeader', {
              name: electionList.name[i18n.language],
              mandates: listResult.mandates,
            })}
          >
            <div className={classes.listResultContent}>
              <div className={classes.listResultHeader}>
                {t('admin.listElec.result.listVotes', {
                  listVotes: listResult.list_votes,
                  mandates: listResult.mandates,
                })}
              </div>
              {listResult.mandates > 0 ? (
                <>
                  <div className={classes.listResultSubHeader}>
                    {t('admin.listElec.elected')}
                  </div>
                  <div>
                    <ol>
                      {electedCandidates.map(
                        ([candidate_id, candidate_votes]: [
                          candidate_id: string,
                          candidate_votes: number
                        ]) => (
                          <li
                            className={classes.candidateListItem}
                            key={candidate_id}
                          >
                            {t('admin.listElec.electedCandidate', {
                              name: idToCandidate[candidate_id].name,
                              votes: candidate_votes,
                            })}
                          </li>
                        )
                      )}
                    </ol>
                  </div>
                  <div className={classes.listResultSubHeader}>
                    {t('admin.listElec.deputy')}
                  </div>
                  <div>
                    {deputyCandidates.length === 0 ? (
                      <div>{t('admin.listElec.noMoreCandidates')}</div>
                    ) : (
                      <ol>
                        {deputyCandidates.map(
                          ([candidate_id, candidate_votes]: [
                            candidate_id: string,
                            candidate_votes: number
                          ]) => (
                            <li
                              className={classes.candidateListItem}
                              key={candidate_id}
                            >
                              {t('admin.listElec.electedCandidate', {
                                name: idToCandidate[candidate_id].name,
                                votes: candidate_votes,
                              })}
                            </li>
                          )
                        )}
                      </ol>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.listResultSubHeader}>
                    {t('admin.listElec.votes')}
                  </div>
                  <ol>
                    {listResult.sorted_candidates_with_votes.map(
                      ([candidate_id, candidate_votes]: [
                        candidate_id: string,
                        candidate_votes: number
                      ]) => (
                        <li
                          className={classes.candidateListItem}
                          key={candidate_id}
                        >
                          {t('admin.listElec.electedCandidate', {
                            name: idToCandidate[candidate_id].name,
                            votes: candidate_votes,
                          })}
                        </li>
                      )
                    )}
                  </ol>
                </>
              )}
            </div>
          </PageExpandableSubSection>
        );
      })}
    </>
  );
}

export default function ListElectionResultAndBallotStats({
  electionResult,
}: {
  electionResult: ElectionResult;
}) {
  const { t } = useTranslation();

  const theme = useTheme();
  const classes = useStyles({ theme });

  const { election } = electionResult;
  // const { pollbooks } = election;
  const { result } = electionResult;

  return (
    <>
      <div className={classes.sectionLevel1}>
        <H4>{t('admin.countingDetails.electionResult.electionResult')}</H4>
        <>
          <div className={classes.sectionLevel2}>
            {Object.entries(result.list_result).length > 0 ? (
              <ListElectionResultList
                result={result.list_result}
                electionLists={election.lists}
              />
            ) : (
              <em>{t('admin.pollElec.noResults')}</em>
            )}
          </div>
        </>
      </div>

      {/*<div className={classes.sectionLevel1}>
        <H4>{t('admin.countingDetails.electionResult.numberOfVotes')}</H4>

        {pollbooks.map((pollbook) => {
          const pollbookBallotStats = result.meta.pollbooks.find(
            (pollbookBallotStat: any) => pollbookBallotStat.id === pollbook.id
          );
          if (!pollbookBallotStats)
            return (
              <span key={pollbook.id} className={classes.errorText}>
                {t(
                  'admin.countingDetails.electionResult.errors.couldNotGetNumberOfVotesData'
                )}
              </span>
            );
          const ballotsCount = pollbookBallotStats.ballots_count;
          const blankBallotsCount = pollbookBallotStats.empty_ballots_count;
          const countingBallotsCounts = ballotsCount - blankBallotsCount;
          return (
            <div className={classes.sectionLevel2}>
              {pollbooks.length > 1 ? (
                <>
                  <strong>{pollbook.name[i18n.language]}:</strong>{' '}
                </>
              ) : null}
              {t('admin.countingDetails.electionResult.countCastVotes', {
                count: ballotsCount,
              })}
              {', '}
              {t('admin.countingDetails.electionResult.ofWhich')}{' '}
              {t('admin.countingDetails.electionResult.countCountingVotes', {
                count: countingBallotsCounts,
              })}{' '}
              {t('general.and')}{' '}
              {t('admin.countingDetails.electionResult.countBlankVotes', {
                count: blankBallotsCount,
              })}
            </div>
          );
        })}
      </div>
      */}
    </>
  );
}
