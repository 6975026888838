import { InfoList } from 'components/infolist';
import Text from 'components/text';
import { InactiveComponentProps } from 'components/page/SettingsSection';

export default function ElectionNameValues({
  electionGroup,
}: InactiveComponentProps) {
  return (
    <InfoList>
      <Text>{electionGroup.name.en}</Text>
      <Text>{electionGroup.name.nb}</Text>
      <Text>{electionGroup.name.nn}</Text>
    </InfoList>
  );
}
