import { ApolloError } from '@apollo/client/errors';
import { ServerError } from '@apollo/client/link/utils';
import Alert from 'components/alert';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

import { MutationResponse } from 'interfaces';

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  rawMsg: {
    border: '2px',
    borderStyle: 'solid',
    borderColor: 'grey',
    margin: '2rem 10rem 2rem 3rem',
    padding: '1rem 1rem 1rem 1rem',
  },
});

interface GraphQLDataParserProps {
  data: MutationResponse | undefined;
  errors: ApolloError | undefined;
  errorMsg: string;
  okMsg: string;
  onClose: () => void;
}

export default function GraphQLResponseParser({
  data,
  errors,
  errorMsg,
  okMsg,
  onClose,
}: GraphQLDataParserProps) {
  const classes = useStyles();
  const { t } = useTranslation('common');

  if (!data && !errors) {
    return null;
  }

  if (errors) {
    if (!errors) {
      return null;
    }

    if (errors.networkError) {
      const networkError = errors.networkError as ServerError;
      return (
        <Alert
          header={t('api.networkError')}
          onClose={onClose}
          severity="error"
        >
          <div className={classes.wrapper}>
            <div>{errorMsg}</div>
            <div>
              {t('api.statusCode')}: {networkError.statusCode}
            </div>
            <div>
              {t('api.errorMsg')}: {networkError.message}
            </div>
            <div className={classes.rawMsg}>
              <pre>{JSON.stringify(networkError.result, null, 2)}</pre>
            </div>
          </div>
        </Alert>
      );
    }

    if (errors.graphQLErrors && errors.graphQLErrors.length > 0) {
      return (
        <Alert
          header={t('api.graphQLError')}
          onClose={onClose}
          severity="error"
        >
          <div className={classes.wrapper}>
            <div>{errorMsg}</div>
            <ul>
              <ul>
                {errors.graphQLErrors.map((e, i) => (
                  <li key={i}>
                    <div className={classes.rawMsg}>
                      <pre>{JSON.stringify(e, null, 2)}</pre>
                    </div>
                  </li>
                ))}
              </ul>
            </ul>
          </div>
        </Alert>
      );
    }
  }

  if (data) {
    if (!data.success) {
      return (
        <Alert
          header={t('api.graphQLError')}
          onClose={onClose}
          severity="error"
        >
          <div className={classes.wrapper}>
            <div>{errorMsg}</div>
            <div>
              {t('api.statusCode')}: {data.code}
            </div>
            <div>
              {t('api.errorMsg')}: {data.message}
            </div>
          </div>
        </Alert>
      );
    }

    return (
      <Alert onClose={onClose} severity="success">
        <div className={classes.wrapper}>
          <div>{okMsg}</div>
        </div>
      </Alert>
    );
  }

  return null;
}
