
const emailRE = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const feideIdRE = /^[a-zæøåA-ZÆØÅ0-9_][a-zæøåA-ZÆØÅ0-9_.-]*@[a-zæøåA-ZÆØÅ0-9_.]+$/;
const ninRE = /^\d{11}$/;

export const validateEmail = (email: string) => emailRE.test(email);

export const validateUrl = (url: any) => {
  try {
    new URL(url)
    return true
  } catch (TypeError) {
    return false
  }
}

export const validateFeideId = (value: string) => feideIdRE.test(value);

export const validateNin = (value: string) => ninRE.test(value);
