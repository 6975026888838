import theme from 'theme';

const nmhblue = '#4124FB';

const NMHTheme = {
  actionTextColor: nmhblue,
  dropDownDesktopMenuColor: theme.colors.black,
  footerBackgroundColor: theme.colors.black,
  footerTextColor: theme.colors.white,
  headerDescColor: theme.colors.black,
  headerMainAreaColor: theme.colors.white,
  linkColor: theme.colors.black,
  loginPageBgColor: theme.colors.white,
  navMenuTextColor: theme.colors.black,
};

export default NMHTheme;
