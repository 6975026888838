import { gql, useMutation } from '@apollo/client';
import arrayMutators from 'final-form-arrays';
import { Field, Form } from 'react-final-form';
import { useTranslation, TFunction } from 'react-i18next';

import { FormButtons, FormField } from 'components/form';
import { TextInput } from 'components/newForm';
import { PageSubSection } from 'components/page';
import { ElectionNameInput, ElectionGroup } from 'interfaces';
import { getSupportedLanguages } from 'utils/i18n';

const updateElectionName = gql`
  mutation UpdateElectionGroupName(
    $electionGroupId: UUID!
    $name: ElectionName!
  ) {
    updateElectionGroupName(
      electionGroupId: $electionGroupId
      nameDict: $name
    ) {
      ok
    }
  }
`;

interface UpdateElectionGroupNameData {
  updateBaseSettings: {
    ok: boolean;
  };
}

function validate(values: ElectionNameInput, t: TFunction) {
  const errors = {
    name: {},
  };

  if (values.name) {
    getSupportedLanguages().forEach((lang) => {
      if (!values.name[lang]) {
        errors.name[lang] = t('info.electionName.fieldErrors.nameMissing', {
          lang: t(`general.lang.${lang}`, { ns: 'common' }).toLowerCase(),
        });
      }
    });
  } else {
    getSupportedLanguages().forEach((lang) => {
      errors.name[lang] = t('info.electionName.fieldErrors.nameMissing', {
        lang: t(`general.lang.${lang}`, { ns: 'common' }).toLowerCase(),
      });
    });
  }
  return errors;
}

function handleFormSubmit(
  values: ElectionNameInput,
  initialValues: ElectionNameInput,
  updateElectionGroupName: (props: any) => void,
  onSubmit: () => void
) {
  if (values.name === initialValues.name) {
    // Only call the api when the name change.
    onSubmit();
    return;
  }
  updateElectionGroupName({
    variables: {
      electionGroupId: values.id,
      name: values.name,
    },
    onCompleted: () => {
      onSubmit();
    },
  });
}

interface ElectionNameFormProps {
  electionGroup: ElectionGroup;
  onSubmit: () => void;
  closeAction: () => void;
}

export default function ElectionNameForm({
  closeAction,
  electionGroup,
  onSubmit,
}: ElectionNameFormProps) {
  const { t } = useTranslation(['admin', 'common']);

  const initialValues: ElectionNameInput = {
    id: electionGroup.id,
    name: electionGroup.name,
  };

  const [updateElectionGroupName, { loading }] = useMutation<
    UpdateElectionGroupNameData,
    ElectionNameInput
  >(updateElectionName, {
    refetchQueries: ['electionGroup'],
    awaitRefetchQueries: true,
  });

  return (
    <Form
      initialValues={initialValues}
      keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      onSubmit={(values: ElectionNameInput) =>
        handleFormSubmit(
          values,
          initialValues,
          updateElectionGroupName,
          onSubmit
        )
      }
      validate={(values: ElectionNameInput) => validate(values, t)}
    >
      {(formProps) => {
        const { handleSubmit, valid, submitting } = formProps;
        return (
          <form onSubmit={handleSubmit}>
            <PageSubSection>
              <FormField>
                <Field
                  name={'name.en'}
                  component={TextInput}
                  label={t('info.electionName.name.en')}
                  placeholder={electionGroup.name.en}
                />
              </FormField>
              <FormField>
                <Field
                  name={'name.nb'}
                  component={TextInput}
                  label={t('info.electionName.name.nb')}
                  placeholder={electionGroup.name.nb}
                />
              </FormField>
              <FormField>
                <Field
                  name={'name.nn'}
                  component={TextInput}
                  label={t('info.electionName.name.nn')}
                  placeholder={electionGroup.name.nn}
                />
              </FormField>
            </PageSubSection>
            <FormButtons
              submitting={loading}
              saveAction={handleSubmit}
              closeAction={closeAction}
              submitDisabled={!valid || submitting || loading}
            />
          </form>
        );
      }}
    </Form>
  );
}
