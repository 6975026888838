import { useEffect } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';

import { Trans, useTranslation } from 'react-i18next';

import { ErrorPageSection, ErrorInline } from 'components/errors';
import { Page, PageSection } from 'components/page';
import Loading from 'components/loading';
import { ElectionGroup, IVoter } from 'interfaces';
import { ElectionGroupFields, ElectionFields } from 'fragments';
import { electionGroupWithOrderedElections } from 'utils/processGraphQLData';

import { MsgBox } from 'components/msgbox';
import { showUserMsg } from 'appConfig';
import VoterElections from './components/VoterElections';

const electionGroupsQuery = gql`
  ${ElectionGroupFields}
  ${ElectionFields}
  query electionGroups($filterOld: Boolean) {
    electionGroups(filterOld: $filterOld) {
      ...ElectionGroupFields
      elections {
        ...ElectionFields
        electionGroup {
          id
        }
      }
    }
  }
`;

const votersForPersonQuery = gql`
  query votersForPerson($id: UUID!) {
    votersForPerson(id: $id) {
      verified
      hasVoted
      pollbook {
        id
        election {
          id
          name
          electionGroup {
            id
          }
        }
      }
    }
  }
`;

export const signedInPersonQuery = gql`
  query {
    viewer {
      person {
        id
      }
    }
  }
`;

export default function VoterFrontPage() {
  const { t } = useTranslation();

  const {
    data: signedInPersonId,
    error: signedInPersonIdError,
    loading: signedInPersonIdLoading,
  } = useQuery(signedInPersonQuery);

  const {
    data: electionGroups,
    error: electionGroupsError,
    loading: electionGroupsLoading,
  } = useQuery(electionGroupsQuery, {
    variables: { filterOld: true },
    fetchPolicy: 'network-only',
  });

  const [
    getVotersForPerson,
    {
      called: votersForPersonCalled,
      data: votersForPerson,
      loading: votersForPersonLoading,
      error: votersForPersonError,
    },
  ] = useLazyQuery(votersForPersonQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (signedInPersonId) {
      getVotersForPerson({
        variables: { id: signedInPersonId.viewer.person.id },
      });
    }
  }, [signedInPersonId, getVotersForPerson]);

  if (signedInPersonIdError) {
    return (
      <ErrorInline
        errorMessage={t('voter.voterFrontPage.errors.couldNotFetchPersonId')}
      />
    );
  }
  if (signedInPersonId === '' || signedInPersonIdLoading) {
    return <Loading>{t('voter.voterFrontPage.loadingUserData')}</Loading>;
  }

  if (
    electionGroupsLoading ||
    votersForPersonLoading ||
    !votersForPersonCalled
  ) {
    return <Loading>{t('voter.voterFrontPage.loadingElections')}</Loading>;
  }
  if (electionGroupsError) {
    return <ErrorPageSection errorMessage={electionGroupsError.message} />;
  }

  if (votersForPersonError) {
    return <ErrorPageSection errorMessage={votersForPersonError.message} />;
  }

  const userVerifiedVoters = votersForPerson.votersForPerson.filter(
    (voter: IVoter) => voter.verified
  );
  const userVerifiedInElectionGroupIds = userVerifiedVoters.map(
    (voter: IVoter) => voter.pollbook.election.electionGroup.id
  );
  const userHasVotedElectionGroup = votersForPerson.votersForPerson.filter(
    (voter: IVoter) => voter.hasVoted
  );
  const userHasVotedElectionGroupIds = userHasVotedElectionGroup.map(
    (voter: IVoter) => voter.pollbook.election.electionGroup.id
  );

  return (
    <Page header={t('general.welcome')}>
      {showUserMsg && (
        <MsgBox
          msg={
            <Trans t={t} components={[<a href="https://valg2.uio.no">text</a>]}>
              loginPage.message
            </Trans>
          }
          timeout={false}
          warning
        />
      )}
      <PageSection desc={t('general.frontPageDesc')} noBorder>
        <VoterElections
          hasVotedElectionGroup={userHasVotedElectionGroupIds}
          votingRightsElectionGroups={userVerifiedInElectionGroupIds}
          electionGroups={electionGroups.electionGroups
            .map((eg: ElectionGroup) =>
              electionGroupWithOrderedElections(eg, {
                onlyActiveElections: true,
              })
            )
            .filter((eg: ElectionGroup) => eg.elections.length > 0)}
        />
      </PageSection>
    </Page>
  );
}
