import {
  ActiveComponentProps,
  InactiveComponentProps,
  ISettingsSectionContents,
} from 'components/page/SettingsSection';

import VoterInfoForm from './VoterInfoForm';
import VoterInfoValues from './VoterInfoValues';

function ActiveComponent({
  closeAction,
  electionGroup,
  submitAction,
}: ActiveComponentProps) {
  return (
    <VoterInfoForm
      onSubmit={submitAction}
      electionGroup={electionGroup}
      closeAction={closeAction}
    />
  );
}

function InactiveComponent({ electionGroup }: InactiveComponentProps) {
  return <VoterInfoValues electionGroup={electionGroup} />;
}

const VoterInfoSettingsSection: ISettingsSectionContents = {
  sectionName: 'voterInfoSettings',
  activeComponent: ActiveComponent,
  inactiveComponent: InactiveComponent,
  header: 'info.voterInfo.header',
  description: 'info.voterInfo.desc',
};

export default VoterInfoSettingsSection;
