const backgroundBlue = '#edf5fc'
const mainBlue = '#355d98'

const HiMoldeTheme = {
    headerMainAreaColor: mainBlue,
    dropDownDesktopMenuColor: backgroundBlue,
    linkColor: backgroundBlue,
    headerDescColor: backgroundBlue,

    footerBackgroundColor: mainBlue,
    footerTextColor: backgroundBlue,
    loginPageBgColor: backgroundBlue,
};

export default HiMoldeTheme
