import {
  ActiveComponentProps,
  InactiveComponentProps,
  ISettingsSectionContents,
} from 'components/page/SettingsSection';

import VotingPeriodForm from './VotingPeriodForm';
import VotingPeriodValues from './VotingPeriodValues';

function ActiveComponent({
  closeAction,
  electionGroup,
  submitAction,
}: ActiveComponentProps) {
  return (
    <VotingPeriodForm
      closeAction={closeAction}
      electionGroup={electionGroup}
      onSubmit={submitAction}
    />
  );
}

function InactiveComponent({ electionGroup }: InactiveComponentProps) {
  const activeElections = electionGroup.elections.filter((e) => e.active);
  return (
    <VotingPeriodValues
      electionGroup={electionGroup}
      activeElections={activeElections}
    />
  );
}

const VotingPeriodSettingsSection: ISettingsSectionContents = {
  sectionName: 'votingPeriodSettings',
  activeComponent: ActiveComponent,
  inactiveComponent: InactiveComponent,
  header: 'info.votingPeriod.header',
  description: 'info.votingPeriod.desc',
};

export default VotingPeriodSettingsSection;
