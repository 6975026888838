import { useTranslation } from 'react-i18next';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  logoBar: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: theme.appMaxWidth,
    padding: `0 ${theme.horizontalPadding}`,
    [theme.breakpoints.mdQuery]: {
      padding: `0 ${theme.horizontalMdPadding}`,
    },
  },
  logoBarWrapper: {
    backgroundColor: theme.headerMainAreaColor,
  },
  logo: {
    marginTop: '3rem',
    maxHeight: '10rem',
    maxWidth: '100%',
    backgroundSize: 'contain',
    '& path': {
      fill: 'white',
    },
  },
}));

function INNLogoNO() {
  return (
    <svg
      width="242"
      height="37"
      viewBox="0 0 242 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.8958 3.05176e-05H20.7076V22.9848H21.8958V3.05176e-05Z" />
      <path d="M21.8958 30.8172H20.7076V36.4796H21.8958V30.8172Z" />
      <path d="M29.6074 3.05176e-05H28.4192V22.9848H29.6074V3.05176e-05Z" />
      <path d="M29.6074 30.8172H28.4192V36.4796H29.6074V30.8172Z" />
      <path d="M0.00170898 12.8907L0.00170898 14.0789L20.9763 14.0789L20.9763 12.8907L0.00170898 12.8907Z" />
      <path d="M29.6086 12.8901L29.6086 14.0783L42.486 14.0783L42.486 12.8901L29.6086 12.8901Z" />
      <path d="M6.64596 3.05176e-05H5.45776V5.65857H6.64596V3.05176e-05Z" />
      <path d="M6.64596 13.4968H5.45776V36.4796H6.64596V13.4968Z" />
      <path d="M14.2719 3.05176e-05H13.0837V5.65857H14.2719V3.05176e-05Z" />
      <path d="M14.2719 13.4968H13.0837V36.4796H14.2719V13.4968Z" />
      <path d="M0.00146484 5.2417L0.00146484 6.4299L20.9761 6.4299L20.9761 5.2417L0.00146484 5.2417Z" />
      <path d="M29.6084 5.24106L29.6084 6.42926L42.4857 6.42926L42.4857 5.24106L29.6084 5.24106Z" />
      <path d="M42.4868 0.171448H36.2283V6.42993H42.4868V0.171448Z" />
      <path d="M42.4868 12.889H36.2283V36.4796H42.4868V12.889Z" />
      <path d="M73.1928 36.4796L57.4969 12.8832H65.0995L76.6113 30.1374H82.6283V36.4796H73.1928Z" />
      <path d="M98.3436 0.171448L114.03 23.7679H106.429L94.8842 6.43967H88.914L88.9062 0.171448H98.3436Z" />
      <path d="M95.1647 12.889H88.9062V36.4796H95.1647V12.889Z" />
      <path d="M82.6302 0.171448H76.3717V23.8614H82.6302V0.171448Z" />
      <path d="M116.352 12.889H122.674V36.4796H115.025L110.726 30.2387H116.352V12.889Z" />
      <path d="M55.0796 36.4796H48.7568V0.171448H56.4061L60.705 6.4124H55.0796V36.4796Z" />
      <path d="M122.674 0.171448H116.416V6.42993H122.674V0.171448Z" />
      <path d="M0.000244141 30.2224L0.000244141 31.4106L6.05225 31.4106L6.05225 30.2224L0.000244141 30.2224Z" />
      <path d="M0 22.5734L0 23.7616L6.05201 23.7616L6.05201 22.5734L0 22.5734Z" />
      <path d="M13.853 30.222L13.853 31.4102L33.1291 31.4102L33.1291 30.222L13.853 30.222Z" />
      <path d="M13.8528 22.573L13.8528 23.7612L33.1288 23.7612L33.1288 22.573L13.8528 22.573Z" />
      <path d="M132.009 15.5767H138.292L136.525 14.0059V8.37737H143.615V14.0059L141.848 15.5767H148.131L146.364 14.0059V1.83255L148.109 0.261794H141.848L143.615 1.83255V7.1993H136.525V1.83255L138.292 0.261794H132.009L133.776 1.83255V14.0059L132.009 15.5767Z" />
      <path d="M157.48 5.54128L158.069 4.05779L157.196 3.68692L156.607 5.19223C156.106 5.06133 155.56 4.97406 155.015 4.97406C151.96 4.97406 149.583 7.3302 149.583 10.4063C149.583 12.6097 150.804 14.4204 152.593 15.2931L152.004 16.7984L152.899 17.1474L153.488 15.6421C153.968 15.773 154.491 15.8385 155.015 15.8385C158.069 15.8385 160.447 13.4823 160.447 10.4063C160.447 8.22466 159.269 6.41393 157.48 5.54128ZM152.462 10.9299V9.88268C152.462 7.39565 153.313 5.89034 155.015 5.89034C155.473 5.89034 155.866 5.99942 156.193 6.19576L153.139 13.9187C152.68 13.2424 152.462 12.217 152.462 10.9299ZM157.567 10.9299C157.567 13.4169 156.716 14.9222 155.015 14.9222C154.578 14.9222 154.208 14.8349 153.88 14.6386L156.935 6.95933C157.349 7.63562 157.567 8.63916 157.567 9.88268V10.9299Z" />
      <path d="M168.935 14.115L165.292 13.5041V12.1734C165.794 12.3261 166.339 12.3915 166.906 12.3915C169.524 12.3915 171.4 10.9517 171.4 8.85732C171.4 7.98468 171.073 7.41746 170.462 6.76298L172.753 5.23586H166.906C164.157 5.23586 162.347 6.93751 162.347 8.85732C162.347 10.2972 163.35 11.4534 164.834 12.0207L162.325 14.8786L165.052 15.3585C163.132 15.8385 161.998 16.7766 161.998 18.0637C161.998 19.5036 163.612 20.7035 166.972 20.7035C170.331 20.7035 172.229 18.98 172.229 17.0383C172.229 15.7512 171.64 14.5731 168.935 14.115ZM165.052 8.72643C165.052 6.93751 165.728 5.89034 166.906 5.89034C168.084 5.89034 168.782 6.93751 168.782 8.72643V8.90096C168.782 10.6899 168.084 11.737 166.906 11.737C165.728 11.737 165.052 10.6899 165.052 8.90096V8.72643ZM167.19 19.7872C165.335 19.7872 164.485 18.9145 164.485 17.7801C164.485 17.082 164.834 16.253 165.924 15.533L168.521 15.9912C169.655 16.1875 170.266 16.6675 170.266 17.671C170.266 18.8491 169.262 19.7872 167.19 19.7872Z" />
      <path d="M178.127 15.9257C180.177 15.9257 181.857 14.7695 181.857 12.7406C181.857 11.2135 181.05 10.3408 178.323 9.0973C176.556 8.29011 176.032 7.85379 176.032 7.13386C176.032 6.43574 176.665 5.89034 177.538 5.89034C178.519 5.89034 179.414 6.43574 180.832 9.0973L181.443 6.06487C180.526 5.47583 179.065 4.97406 177.756 4.97406C175.334 4.97406 174.047 6.37029 174.047 8.0065C174.047 9.55544 174.876 10.4281 177.778 11.7807C179.436 12.5224 179.916 12.9369 179.916 13.635C179.916 14.3768 179.348 14.8786 178.432 14.8786C176.992 14.8786 175.814 13.526 174.767 11.5625L173.807 14.4204C174.701 15.184 176.425 15.9257 178.127 15.9257Z" />
      <path d="M195.558 15.5767L194.075 14.1804L189.733 9.18457L191.871 7.59199L195.187 5.23586H190.17V7.50473L187.334 9.72997V0L183.254 1.83255L184.847 2.68338V14.2023L183.276 15.5767H188.904L187.334 14.2023V9.94813L191.173 14.4641L189.995 15.5767H195.558Z" />
      <path d="M201.225 15.8385C204.279 15.8385 206.657 13.4823 206.657 10.4063C206.657 7.3302 204.279 4.97406 201.225 4.97406C198.17 4.97406 195.793 7.3302 195.793 10.4063C195.793 13.4823 198.17 15.8385 201.225 15.8385ZM201.225 14.9222C199.523 14.9222 198.672 13.4169 198.672 10.9299V9.88268C198.672 7.39565 199.523 5.89034 201.225 5.89034C202.926 5.89034 203.777 7.39565 203.777 9.88268V10.9299C203.777 13.4169 202.926 14.9222 201.225 14.9222Z" />
      <path d="M207.989 15.5767H213.618L212.047 14.2023V0L208.011 1.83255L209.56 2.63974V14.2023L207.989 15.5767Z" />
      <path d="M220.989 13.7441C218.981 13.7441 217.454 12.2824 217.28 9.68634H224.435C224.435 6.80661 222.581 4.97406 219.941 4.97406C216.996 4.97406 214.989 7.26475 214.989 10.4935C214.989 13.635 216.996 15.8385 219.985 15.8385C222.712 15.8385 224.305 13.875 224.305 11.7807C223.65 12.9587 222.472 13.7441 220.989 13.7441ZM219.701 5.89034C221.272 5.89034 221.948 7.3302 221.948 8.77006H217.28C217.411 6.80661 218.48 5.89034 219.701 5.89034Z" />
      <path d="M232.805 15.5767H238.434L236.863 14.2023V8.11558C236.863 5.86852 235.838 4.97406 234.201 4.97406C232.652 4.97406 231.278 5.69399 229.925 6.7848V4.84317L225.933 6.67572L227.438 7.4611V14.2023L225.868 15.5767H231.496L229.925 14.2023V7.1993C230.776 6.91569 231.562 6.74117 232.26 6.74117C233.656 6.74117 234.376 7.3302 234.376 9.03185V14.2023L232.805 15.5767Z" />
      <path d="M133.885 24.4149C134.866 24.4149 135.652 23.6296 135.652 22.6478C135.652 21.6661 134.866 20.8807 133.885 20.8807C132.903 20.8807 132.118 21.6661 132.118 22.6478C132.118 23.6296 132.903 24.4149 133.885 24.4149ZM131.31 36.4574H136.939L135.368 35.083V25.7239L131.332 27.5128L132.881 28.3637V35.083L131.31 36.4574Z" />
      <path d="M143.848 36.4574H150.131L148.364 34.8867V22.7133L150.131 21.1425H143.848L145.615 22.7133V34.8867L143.848 36.4574Z" />
      <path d="M158.679 36.4574H164.308L162.737 35.083V28.9963C162.737 26.7493 161.712 25.8548 160.075 25.8548C158.526 25.8548 157.152 26.5747 155.799 27.6655V25.7239L151.807 27.5565L153.312 28.3418V35.083L151.742 36.4574H157.37L155.799 35.083V28.08C156.65 27.7964 157.436 27.6219 158.134 27.6219C159.53 27.6219 160.25 28.2109 160.25 29.9126V35.083L158.679 36.4574Z" />
      <path d="M172.229 36.4574H177.858L176.287 35.083V28.9963C176.287 26.7493 175.261 25.8548 173.625 25.8548C172.076 25.8548 170.702 26.5747 169.349 27.6655V25.7239L165.357 27.5565L166.862 28.3418V35.083L165.292 36.4574H170.92L169.349 35.083V28.08C170.2 27.7964 170.986 27.6219 171.684 27.6219C173.08 27.6219 173.8 28.2109 173.8 29.9126V35.083L172.229 36.4574Z" />
      <path d="M178.689 36.4574H184.317L182.746 35.083V20.8807L178.71 22.7133L180.259 23.5205V35.083L178.689 36.4574Z" />
      <path d="M192.059 36.8937L196.029 35.0612L194.546 34.3413V29.2799C194.546 26.8583 193.673 25.9203 190.859 25.9203C187.39 25.9203 185.994 27.3601 185.994 28.6691C185.994 29.4545 186.496 29.978 187.303 29.978C189.353 29.978 188.917 26.5747 190.902 26.5747C191.666 26.5747 192.059 27.0329 192.059 28.2109V30.1744C189.004 30.7852 185.623 31.9851 185.623 34.4503C185.623 35.8466 186.757 36.7192 188.394 36.7192C189.942 36.7192 191.121 35.9556 192.059 34.8867V36.8937ZM189.964 35.0176C188.917 35.0176 188.328 34.3631 188.328 33.5777C188.328 32.6178 188.83 31.7451 192.059 30.7634V34.1667C191.339 34.7121 190.684 35.0176 189.964 35.0176Z" />
      <path d="M203.93 36.4574H209.559L207.988 35.083V28.9963C207.988 26.7493 206.963 25.8548 205.327 25.8548C203.778 25.8548 202.403 26.5747 201.051 27.6655V25.7239L197.058 27.5565L198.564 28.3418V35.083L196.993 36.4574H202.622L201.051 35.083V28.08C201.902 27.7964 202.687 27.6219 203.385 27.6219C204.781 27.6219 205.501 28.2109 205.501 29.9126V35.083L203.93 36.4574Z" />
      <path d="M221.996 35.0612L220.556 34.3413V20.8807L216.542 22.7133L218.069 23.5205V26.2693C217.437 25.9857 216.848 25.8766 216.128 25.8766C212.877 25.8766 210.892 28.4291 210.892 31.7233C210.892 34.6685 212.659 36.7192 215.081 36.7192C216.28 36.7192 217.284 36.1738 218.069 34.9085V36.8937L221.996 35.0612ZM216.499 35.0176C214.95 35.0176 213.553 33.8177 213.553 31.0907C213.553 28.1237 214.775 26.7056 216.368 26.7056C217.459 26.7056 218.069 27.3601 218.069 28.7782V34.4503C217.568 34.8648 217.131 35.0176 216.499 35.0176Z" />
      <path d="M229.418 34.6249C227.411 34.6249 225.884 33.1632 225.71 30.5671H232.865C232.865 27.6874 231.011 25.8548 228.371 25.8548C225.426 25.8548 223.419 28.1455 223.419 31.3743C223.419 34.5158 225.426 36.7192 228.415 36.7192C231.142 36.7192 232.735 34.7558 232.735 32.6614C232.08 33.8395 230.902 34.6249 229.418 34.6249ZM228.131 26.7711C229.702 26.7711 230.378 28.2109 230.378 29.6508H225.71C225.841 27.6874 226.91 26.7711 228.131 26.7711Z" />
      <path d="M238.53 36.7192C239.992 36.7192 241.322 35.9993 241.824 34.6685C241.322 35.1266 240.646 35.4102 239.817 35.4102C239.01 35.4102 238.53 35.0176 238.53 34.014V27.1638H241.846V26.1166H238.53V22.9751L233.818 27.1638H236.043V33.8395C236.043 36.0647 237.025 36.7192 238.53 36.7192Z" />
    </svg>
  );
}

function INNLogoEN() {
  return (
    <svg
      width="335"
      height="37"
      viewBox="0 0 335 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1005_1062)">
        <path d="M127.05 10.77V1.62L125.74 0.229996H130.88L129.57 1.62V10.77L130.88 12.16H125.74L127.05 10.77Z" />
        <path d="M138.82 10.89V7.27999C138.82 5.91999 138.14 5.40999 137.05 5.40999C136.545 5.42566 136.044 5.50632 135.56 5.64999V10.89L136.73 12.16H132.02L133.29 10.89V5.78999L132.1 5.10999L135.57 3.64999V5.18999C136.54 4.34184 137.764 3.83898 139.05 3.75999C140.31 3.75999 141.13 4.52999 141.13 6.17999V10.89L142.4 12.16H137.68L138.82 10.89Z" />
        <path d="M144.46 10.89V2.2L143.24 1.55L146.71 0.0800018V10.89L147.95 12.16H143.22L144.46 10.89Z" />
        <path d="M153.59 11.19C153.247 11.564 152.83 11.8622 152.365 12.0654C151.9 12.2686 151.397 12.3724 150.89 12.37C150.617 12.407 150.339 12.3853 150.075 12.3064C149.811 12.2275 149.566 12.0932 149.358 11.9125C149.15 11.7317 148.983 11.5087 148.867 11.2583C148.752 11.0078 148.692 10.7357 148.69 10.46C148.69 8.95001 150.26 7.74001 153.59 7.16001V5.48001C153.59 4.73001 153.32 4.48001 152.82 4.48001C151.36 4.48001 151.65 7.17001 150 7.17001C149.84 7.18938 149.678 7.17219 149.526 7.11974C149.375 7.06729 149.236 6.98095 149.123 6.86726C149.009 6.75357 148.923 6.61551 148.87 6.46353C148.818 6.31154 148.801 6.14962 148.82 5.99001C148.82 4.99001 150.14 3.85001 152.82 3.85001C155.2 3.85001 155.82 4.55001 155.82 6.91001V10.39L157.01 11.14L153.59 12.55V11.19ZM153.59 10.55V7.67001C151.69 8.42001 151.18 9.17001 151.18 9.87001C151.166 10.0247 151.189 10.1804 151.245 10.325C151.302 10.4695 151.392 10.5988 151.507 10.7026C151.623 10.8064 151.761 10.8819 151.911 10.9231C152.06 10.9644 152.218 10.9701 152.37 10.94C152.805 10.9276 153.228 10.7924 153.59 10.55V10.55Z" />
        <path d="M164.38 10.89V7.27999C164.38 5.91999 163.7 5.40999 162.61 5.40999C162.102 5.42612 161.598 5.50677 161.11 5.64999V10.89L162.29 12.16H157.56L158.82 10.89V5.78999L157.63 5.10999L161.1 3.64999V5.18999C162.074 4.34175 163.301 3.83904 164.59 3.75999C165.85 3.75999 166.66 4.52999 166.66 6.17999V10.89L167.94 12.16H163.22L164.38 10.89Z" />
        <path d="M174.11 12.55V11.19C173.862 11.5666 173.52 11.8729 173.119 12.0796C172.718 12.2862 172.271 12.3862 171.82 12.37C169.9 12.37 168.64 10.65 168.64 8.32C168.64 5.79 170.17 3.8 172.72 3.8C173.212 3.79063 173.7 3.87549 174.16 4.05V2.05L172.91 1.55L176.43 0.0800018V10.39L177.56 11.14L174.11 12.55ZM174.11 10.64V5.77C174.11 4.97 173.69 4.5 172.97 4.5C171.92 4.5 171.09 5.5 171.09 7.74C171.09 9.98 172.19 10.87 173.3 10.87C173.591 10.8977 173.881 10.8117 174.11 10.63V10.64Z" />
        <path d="M195.67 0.229996L193.99 1.89V12.55L185.82 3.87V10.55L187.68 12.21H182.36L184.23 10.55V2.42L182 0.229996H186L192.43 7.13V1.89L190.67 0.229996H195.67Z" />
        <path d="M196.18 8.08C196.152 7.50387 196.241 6.92807 196.443 6.38751C196.644 5.84694 196.953 5.35287 197.351 4.93524C197.748 4.51762 198.227 4.18514 198.757 3.95797C199.287 3.7308 199.858 3.61366 200.435 3.61366C201.012 3.61366 201.583 3.7308 202.113 3.95797C202.643 4.18514 203.121 4.51762 203.519 4.93524C203.917 5.35287 204.226 5.84694 204.427 6.38751C204.629 6.92807 204.718 7.50387 204.69 8.08C204.718 8.65613 204.629 9.23193 204.427 9.77249C204.226 10.3131 203.917 10.8071 203.519 11.2248C203.121 11.6424 202.643 11.9749 202.113 12.202C201.583 12.4292 201.012 12.5463 200.435 12.5463C199.858 12.5463 199.287 12.4292 198.757 12.202C198.227 11.9749 197.748 11.6424 197.351 11.2248C196.953 10.8071 196.644 10.3131 196.443 9.77249C196.241 9.23193 196.152 8.65613 196.18 8.08V8.08ZM202.07 8.75V7.4C202.07 5.79 201.72 4.61 200.42 4.61C199.12 4.61 198.82 5.79 198.82 7.4V8.73C198.82 10.36 199.18 11.52 200.45 11.52C201.72 11.52 202.07 10.36 202.07 8.75Z" />
        <path d="M213.09 5.22999C213.097 5.39739 213.069 5.56446 213.008 5.72028C212.946 5.87611 212.852 6.01716 212.732 6.13422C212.613 6.25128 212.469 6.3417 212.312 6.39956C212.155 6.45742 211.987 6.48142 211.82 6.46999C210.89 6.46999 210.5 5.73999 209.82 5.73999C209.713 5.73827 209.606 5.75896 209.508 5.80074C209.409 5.84251 209.32 5.90445 209.247 5.98259C209.173 6.06072 209.117 6.15333 209.081 6.25448C209.046 6.35563 209.032 6.4631 209.04 6.56999V10.89L210.35 12.16H205.48L206.74 10.89V5.78999L205.57 5.10999L209.04 3.64999V5.35999C209.331 4.93292 209.714 4.57591 210.16 4.31477C210.606 4.05363 211.105 3.89488 211.62 3.84999C211.81 3.83136 212.002 3.85354 212.182 3.91504C212.363 3.97655 212.529 4.07597 212.668 4.20664C212.807 4.3373 212.917 4.4962 212.99 4.6727C213.062 4.84919 213.097 5.0392 213.09 5.22999V5.22999Z" />
        <path d="M223.13 12.47L220.42 6.82L217.98 12.47L214.74 5.74L213.46 3.97H218.02L217.02 5.7L218.53 8.92L219.91 5.74L219.72 5.33L218.82 3.97H223.14L222.14 5.7L223.65 8.9L225.03 5.75L224.03 3.97H227.57L226.12 5.72L223.13 12.47Z" />
        <path d="M232.68 11.19C232.337 11.564 231.92 11.8622 231.455 12.0654C230.99 12.2686 230.487 12.3724 229.98 12.37C229.707 12.407 229.429 12.3853 229.165 12.3064C228.901 12.2275 228.656 12.0932 228.448 11.9124C228.24 11.7317 228.073 11.5087 227.958 11.2582C227.842 11.0078 227.782 10.7357 227.78 10.46C227.78 8.95 229.35 7.74 232.68 7.16V5.48C232.68 4.73 232.41 4.48 231.91 4.48C230.45 4.48 230.74 7.17 229.09 7.17C228.936 7.18357 228.782 7.16349 228.637 7.11114C228.492 7.0588 228.36 6.97545 228.25 6.86692C228.141 6.75838 228.056 6.62727 228.003 6.48272C227.949 6.33818 227.928 6.18368 227.94 6.03C227.94 5.03 229.26 3.89 231.94 3.89C234.32 3.89 234.94 4.59 234.94 6.95V10.43L236.13 11.18L232.68 12.55V11.19ZM232.68 10.55V7.67C230.78 8.42 230.27 9.17 230.27 9.87C230.256 10.0247 230.279 10.1804 230.335 10.325C230.392 10.4695 230.482 10.5988 230.597 10.7026C230.713 10.8064 230.851 10.8819 231.001 10.9231C231.15 10.9643 231.308 10.9701 231.46 10.94C231.895 10.9276 232.318 10.7924 232.68 10.55V10.55Z" />
        <path d="M244.65 5.36L241 12.47C239.69 15.02 238.5 15.71 237.43 15.71C236.36 15.71 235.71 15.12 235.71 14.32C235.712 14.1353 235.752 13.9529 235.827 13.7841C235.902 13.6153 236.011 13.4636 236.147 13.3382C236.283 13.2128 236.442 13.1164 236.617 13.0548C236.791 12.9932 236.976 12.9678 237.16 12.98C237.8 12.98 238.16 13.32 238.63 13.32C239.25 13.32 239.63 12.81 240.16 11.62L236.99 5.46L235.74 3.97H240.74L239.74 5.65L241.46 9.31L243.19 5.67L242.19 3.97H245.82L244.65 5.36Z" />
        <path d="M251.32 7.23V1.49L250.02 0.229996H255.34L254 1.49V7.3C254 9.51 254.8 10.87 257.08 10.87C259.2 10.87 259.95 9.44 259.95 7.08V1.71L258.32 0.229996H263.01L261.38 1.71V7.08C261.38 10.68 259.56 12.37 256.53 12.37C253.24 12.37 251.32 10.6 251.32 7.23Z" />
        <path d="M269.82 10.89V7.27999C269.82 5.91999 269.14 5.40999 268.05 5.40999C267.542 5.42612 267.038 5.50677 266.55 5.64999V10.89L267.73 12.16H263L264.28 10.89V5.78999L263.09 5.10999L266.55 3.64999V5.18999C267.525 4.34304 268.751 3.84051 270.04 3.75999C271.3 3.75999 272.11 4.52999 272.11 6.17999V10.89L273.39 12.16H268.66L269.82 10.89Z" />
        <path d="M275.54 10.89V5.89L274.28 5.06L277.82 3.65V10.89L279.09 12.16H274.26L275.54 10.89ZM274.94 1.55C274.94 1.24344 275.031 0.943763 275.201 0.688867C275.372 0.433971 275.614 0.235303 275.897 0.117987C276.18 0.000671588 276.492 -0.0300236 276.792 0.0297835C277.093 0.0895906 277.369 0.237214 277.586 0.453985C277.803 0.670757 277.95 0.94694 278.01 1.24761C278.07 1.54828 278.039 1.85993 277.922 2.14316C277.805 2.42639 277.606 2.66846 277.351 2.83878C277.096 3.00909 276.797 3.1 276.49 3.1C276.286 3.10133 276.084 3.06214 275.895 2.98472C275.707 2.9073 275.535 2.79317 275.391 2.64898C275.247 2.50478 275.133 2.33338 275.055 2.14473C274.978 1.95607 274.939 1.75392 274.94 1.55V1.55Z" />
        <path d="M287.64 5.67L283.94 12.47L280.25 5.65L279.06 3.97H283.7L282.82 5.82L284.58 9.27L286.31 5.77L285.44 3.97H288.82L287.64 5.67Z" />
        <path d="M296.44 9.14C296.459 9.59006 296.38 10.0388 296.209 10.4555C296.038 10.8722 295.778 11.2468 295.448 11.5537C295.119 11.8606 294.727 12.0925 294.299 12.2334C293.871 12.3743 293.418 12.4209 292.97 12.37C292.408 12.3833 291.85 12.278 291.331 12.0611C290.813 11.8441 290.346 11.5204 289.961 11.1109C289.576 10.7014 289.282 10.2154 289.097 9.68459C288.913 9.15374 288.842 8.58996 288.89 8.03C288.853 7.48245 288.929 6.93314 289.113 6.41623C289.298 5.89932 289.586 5.42588 289.962 5.02537C290.337 4.62485 290.79 4.30582 291.294 4.08813C291.798 3.87045 292.341 3.75875 292.89 3.76C293.369 3.74779 293.845 3.83259 294.29 4.0093C294.735 4.18602 295.14 4.45098 295.48 4.78826C295.82 5.12553 296.089 5.52813 296.269 5.97182C296.45 6.41551 296.538 6.89112 296.53 7.37H290.99C291.07 9.44 292.31 10.51 293.99 10.51C294.483 10.5196 294.97 10.3977 295.401 10.1569C295.831 9.91618 296.19 9.56519 296.44 9.14V9.14ZM290.96 6.6H294.19C294.19 5.6 293.75 4.6 292.65 4.6C291.82 4.61 291.1 5.18 290.96 6.6Z" />
        <path d="M304.92 5.22999C304.928 5.40105 304.899 5.57183 304.835 5.73068C304.771 5.88953 304.674 6.03271 304.549 6.15043C304.425 6.26815 304.277 6.35764 304.115 6.41279C303.952 6.46794 303.78 6.48745 303.61 6.46999C302.68 6.46999 302.29 5.73999 301.61 5.73999C301.504 5.73709 301.398 5.75621 301.3 5.79615C301.201 5.83609 301.112 5.89599 301.038 5.97208C300.964 6.04817 300.906 6.1388 300.868 6.23826C300.831 6.33772 300.814 6.44386 300.82 6.54999V10.87L302.12 12.14H297.26L298.52 10.87V5.78999L297.35 5.10999L300.82 3.64999V5.35999C301.11 4.93193 301.493 4.57418 301.939 4.31292C302.385 4.05166 302.885 3.89349 303.4 3.84999C303.594 3.82356 303.791 3.83991 303.978 3.89789C304.165 3.95587 304.337 4.05407 304.482 4.1856C304.627 4.31713 304.741 4.47879 304.817 4.65922C304.892 4.83965 304.928 5.03447 304.92 5.22999V5.22999Z" />
        <path d="M305.76 11.48L306.45 8.89999C307.2 10.16 308.26 11.53 309.45 11.53C309.96 11.53 310.37 11.3 310.37 10.78C310.37 10.26 310.03 9.89999 308.46 9.16999C306.59 8.28999 305.88 7.48999 305.88 6.27999C305.88 4.71999 307.12 3.75999 308.88 3.75999C309.911 3.77235 310.922 4.04404 311.82 4.54999L311.37 7.01999C310.23 5.15999 309.53 4.63999 308.75 4.63999C307.97 4.63999 307.75 4.98999 307.75 5.49999C307.75 6.00999 308.28 6.38999 309.45 6.96999C311.45 7.96999 312.24 8.66999 312.24 9.91999C312.24 11.47 311.01 12.52 309.24 12.52C308.01 12.4791 306.811 12.1208 305.76 11.48V11.48Z" />
        <path d="M314.35 10.89V5.89L313.09 5.06L316.63 3.65V10.89L317.9 12.16H313.07L314.35 10.89ZM313.75 1.55C313.75 1.24344 313.841 0.943763 314.011 0.688867C314.182 0.433971 314.424 0.235303 314.707 0.117987C314.99 0.000671588 315.302 -0.0300236 315.602 0.0297835C315.903 0.0895906 316.179 0.237214 316.396 0.453985C316.613 0.670757 316.76 0.94694 316.82 1.24761C316.88 1.54828 316.849 1.85993 316.732 2.14316C316.615 2.42639 316.416 2.66846 316.161 2.83878C315.906 3.00909 315.607 3.1 315.3 3.1C315.096 3.10133 314.894 3.06214 314.705 2.98472C314.517 2.9073 314.345 2.79317 314.201 2.64898C314.057 2.50478 313.943 2.33338 313.865 2.14473C313.788 1.95607 313.749 1.75392 313.75 1.55V1.55Z" />
        <path d="M319.67 9.97V4.97H317.95L321.95 1.42V3.97H324.43V4.97H321.95V10.24C321.941 10.3736 321.961 10.5076 322.008 10.6329C322.055 10.7582 322.129 10.872 322.223 10.9667C322.318 11.0614 322.432 11.1348 322.557 11.1819C322.682 11.229 322.816 11.2488 322.95 11.24C323.478 11.2376 323.994 11.0774 324.43 10.78C324.172 11.2731 323.777 11.6815 323.293 11.9563C322.809 12.2312 322.256 12.3609 321.7 12.33C320.52 12.37 319.67 11.7 319.67 9.97Z" />
        <path d="M333.99 5.36L330.33 12.47C329.02 15.02 327.83 15.71 326.76 15.71C325.69 15.71 325.04 15.12 325.04 14.32C325.042 14.1353 325.082 13.9529 325.157 13.7841C325.232 13.6153 325.341 13.4636 325.477 13.3382C325.613 13.2128 325.772 13.1164 325.947 13.0548C326.121 12.9932 326.306 12.9678 326.49 12.98C327.14 12.98 327.49 13.32 327.97 13.32C328.45 13.32 328.97 12.81 329.5 11.62L326.32 5.46L325.08 3.97H330.03L329.03 5.65L330.82 9.31L332.56 5.67L331.56 3.97H335.16L333.99 5.36Z" />
        <path d="M125.62 28.55C125.626 27.7083 125.881 26.8873 126.353 26.1905C126.825 25.4937 127.493 24.9523 128.273 24.6348C129.052 24.3173 129.908 24.2377 130.733 24.4063C131.558 24.5749 132.314 24.9839 132.906 25.5819C133.499 26.1798 133.901 26.9399 134.061 27.766C134.222 28.5922 134.135 29.4475 133.81 30.224C133.485 31.0005 132.937 31.6634 132.236 32.129C131.535 32.5945 130.712 32.842 129.87 32.84C129.306 32.8482 128.747 32.7426 128.225 32.5294C127.703 32.3162 127.229 31.9999 126.832 31.5993C126.435 31.1988 126.123 30.7222 125.915 30.1983C125.707 29.6743 125.606 29.1137 125.62 28.55V28.55ZM131.52 29.21V27.9C131.52 26.29 131.17 25.11 129.87 25.11C128.57 25.11 128.24 26.29 128.24 27.9V29.23C128.24 30.86 128.6 32.02 129.87 32.02C131.14 32.02 131.52 30.86 131.52 29.24V29.21Z" />
        <path d="M136.21 31.39V25.47H134.94V24.47H136.21L136.01 23.26C135.926 22.9161 135.926 22.557 136.01 22.2131C136.094 21.8693 136.26 21.5508 136.494 21.2848C136.727 21.0188 137.022 20.8131 137.352 20.6851C137.682 20.5572 138.038 20.5109 138.39 20.55C138.66 20.5197 138.933 20.5446 139.193 20.6233C139.453 20.702 139.694 20.8329 139.902 21.0078C140.11 21.1828 140.28 21.3983 140.401 21.641C140.523 21.8838 140.594 22.1489 140.61 22.42C140.61 22.6852 140.505 22.9396 140.317 23.1271C140.13 23.3146 139.875 23.42 139.61 23.42C138.36 23.42 138.44 21.62 137.76 21.62C137.39 21.62 137.2 21.99 137.47 22.5L138.47 24.5H140.47V25.5H138.47V31.42L139.82 32.66H135.01L136.21 31.39Z" />
        <path d="M153.19 31.32L152.51 29.84H147.89L147.31 31.32L148.67 32.66H144.34L145.97 31.11L150.82 20.42L155.82 31.13L157.27 32.66H152.02L153.19 31.32ZM150.13 24.49L148.31 28.77H152L150.13 24.49Z" />
        <path d="M166.47 28.07C166.47 30.98 164.7 32.76 162.18 32.76C161.673 32.7607 161.169 32.6762 160.69 32.51V34.75L161.95 36.03H157.14L158.39 34.75V26.46L157.19 25.61L160.69 24.14V25.14C161.443 24.6018 162.344 24.3085 163.27 24.3C165.28 24.26 166.47 25.62 166.47 28.07ZM164 28.92C164 27.02 163.43 25.59 161.47 25.59C161.208 25.5882 160.946 25.615 160.69 25.67V30.67C160.69 31.67 161.08 32.05 161.86 32.05C163.09 32.07 164 31.08 164 28.92Z" />
        <path d="M176.58 28.07C176.58 30.98 174.81 32.76 172.29 32.76C171.792 32.7723 171.295 32.7013 170.82 32.55V34.75L172.07 36.03H167.26L168.52 34.75V26.46L167.31 25.61L170.82 24.14V25.14C171.573 24.6018 172.474 24.3085 173.4 24.3C175.39 24.26 176.58 25.62 176.58 28.07ZM174.11 28.92C174.11 27.02 173.53 25.59 171.58 25.59C171.318 25.5882 171.056 25.615 170.8 25.67V30.67C170.8 31.67 171.19 32.05 171.97 32.05C173.19 32.07 174.11 31.08 174.11 28.92Z" />
        <path d="M178.49 31.39V22.7L177.26 22L180.73 20.55V31.39L181.97 32.66H177.24L178.49 31.39Z" />
        <path d="M184.13 31.39V26.39L182.87 25.55L186.41 24.13V31.38L187.68 32.65H182.82L184.13 31.39ZM183.54 22.02C183.536 21.7122 183.624 21.4102 183.792 21.1524C183.96 20.8947 184.202 20.6929 184.485 20.5728C184.769 20.4528 185.081 20.4198 185.384 20.4781C185.686 20.5365 185.964 20.6835 186.182 20.9004C186.401 21.1174 186.55 21.3945 186.61 21.6963C186.67 21.9982 186.639 22.3112 186.521 22.5954C186.403 22.8796 186.203 23.1222 185.946 23.2922C185.689 23.4622 185.388 23.552 185.08 23.55C184.673 23.55 184.283 23.3891 183.995 23.1025C183.706 22.8158 183.543 22.4267 183.54 22.02V22.02Z" />
        <path d="M195.87 29.64C195.887 30.0896 195.807 30.5376 195.635 30.9535C195.464 31.3693 195.204 31.7433 194.875 32.0498C194.546 32.3564 194.154 32.5883 193.727 32.7299C193.3 32.8714 192.847 32.9192 192.4 32.87C191.84 32.883 191.283 32.7783 190.767 32.5625C190.25 32.3468 189.784 32.0248 189.399 31.6176C189.014 31.2103 188.72 30.7268 188.534 30.1984C188.348 29.67 188.275 29.1084 188.32 28.55C188.283 28.0025 188.359 27.4531 188.543 26.9362C188.728 26.4193 189.016 25.9459 189.392 25.5454C189.767 25.1449 190.22 24.8258 190.724 24.6081C191.228 24.3904 191.771 24.2788 192.32 24.28C192.798 24.2692 193.274 24.355 193.719 24.5322C194.163 24.7094 194.567 24.9744 194.907 25.3114C195.247 25.6484 195.515 26.0504 195.696 26.4935C195.877 26.9365 195.967 27.4115 195.96 27.89H190.41C190.5 29.96 191.74 31.03 193.41 31.03C193.906 31.0372 194.396 30.9123 194.828 30.6681C195.261 30.4238 195.62 30.069 195.87 29.64V29.64ZM190.39 27.1H193.62C193.62 26.1 193.18 25.1 192.07 25.1C191.22 25.11 190.53 25.67 190.39 27.1V27.1Z" />
        <path d="M202.67 33.04V31.69C202.416 32.0749 202.065 32.3864 201.652 32.5934C201.24 32.8005 200.781 32.8958 200.32 32.87C198.4 32.87 197.15 31.15 197.15 28.82C197.15 26.29 198.68 24.3 201.23 24.3C201.722 24.2916 202.21 24.3764 202.67 24.55V22.55L201.46 22.02L204.98 20.55V30.89L206.12 31.64L202.67 33.04ZM202.67 31.13V26.27C202.67 25.47 202.25 25 201.53 25C200.48 25 199.64 26 199.64 28.24C199.64 30.48 200.75 31.37 201.85 31.37C202.144 31.3972 202.437 31.3114 202.67 31.13V31.13Z" />
        <path d="M211.14 30.81L212.56 28.02C213.82 30.66 215.03 31.79 216.27 31.79C216.476 31.8199 216.687 31.8036 216.886 31.7423C217.085 31.6811 217.269 31.5764 217.423 31.4358C217.577 31.2952 217.698 31.1222 217.777 30.9293C217.856 30.7363 217.891 30.5282 217.88 30.32C217.88 27.75 211.36 27.68 211.36 23.99C211.36 22.11 212.92 20.52 215.5 20.52C216.974 20.5689 218.403 21.0372 219.62 21.87L218.62 24.77C216.97 22.21 216.17 21.54 215.12 21.54C214.915 21.5149 214.706 21.5328 214.508 21.5926C214.311 21.6524 214.127 21.7527 213.97 21.8872C213.813 22.0217 213.686 22.1875 213.596 22.3739C213.507 22.5603 213.457 22.7633 213.45 22.97C213.45 25.42 220 25.54 220 29.35C220 31.49 218.14 32.87 215.92 32.87C215.023 32.8819 214.134 32.7046 213.31 32.3496C212.487 31.9947 211.747 31.4701 211.14 30.81V30.81Z" />
        <path d="M221.28 28.55C221.254 27.9924 221.342 27.4354 221.539 26.9132C221.736 26.3909 222.038 25.9143 222.425 25.5127C222.813 25.111 223.279 24.7927 223.793 24.5773C224.308 24.3619 224.862 24.254 225.42 24.26C226.045 24.2591 226.663 24.3854 227.237 24.6313C227.811 24.8771 228.33 25.2374 228.76 25.69L227.24 28.55L226.09 25.41C225.821 25.252 225.512 25.1755 225.2 25.19C224.08 25.19 223.25 26.05 223.25 27.72C223.25 29.72 224.47 31.07 226.15 31.07C226.678 31.1015 227.205 30.9899 227.675 30.747C228.145 30.5041 228.54 30.1389 228.82 29.69C228.827 30.124 228.743 30.5546 228.575 30.9547C228.406 31.3548 228.157 31.7156 227.842 32.0144C227.527 32.3132 227.154 32.5435 226.746 32.6908C226.337 32.8381 225.903 32.8991 225.47 32.87C224.906 32.8803 224.346 32.7745 223.824 32.5594C223.302 32.3442 222.831 32.0242 222.438 31.6191C222.045 31.2141 221.739 30.7327 221.54 30.2047C221.341 29.6768 221.253 29.1136 221.28 28.55V28.55Z" />
        <path d="M231.09 31.39V26.39L229.82 25.55L233.35 24.13V31.38L234.63 32.65H229.82L231.09 31.39ZM230.49 22.02C230.486 21.7126 230.574 21.411 230.741 21.1535C230.909 20.896 231.15 20.6942 231.433 20.5738C231.716 20.4534 232.028 20.4199 232.33 20.4774C232.632 20.5349 232.91 20.6809 233.129 20.8969C233.348 21.1128 233.497 21.389 233.559 21.6902C233.62 21.9914 233.591 22.3041 233.474 22.5884C233.357 22.8728 233.158 23.1161 232.903 23.2873C232.648 23.4585 232.347 23.55 232.04 23.55C231.838 23.5513 231.637 23.5128 231.45 23.4366C231.263 23.3604 231.092 23.2481 230.948 23.106C230.804 22.9639 230.69 22.7949 230.611 22.6086C230.532 22.4222 230.491 22.2222 230.49 22.02V22.02Z" />
        <path d="M242.82 29.64C242.839 30.0901 242.76 30.5388 242.589 30.9555C242.418 31.3722 242.158 31.7468 241.828 32.0537C241.499 32.3606 241.107 32.5925 240.679 32.7334C240.251 32.8743 239.798 32.9209 239.35 32.87C238.79 32.883 238.233 32.7782 237.716 32.5625C237.2 32.3467 236.734 32.0248 236.349 31.6176C235.964 31.2103 235.67 30.7268 235.484 30.1984C235.298 29.6699 235.225 29.1084 235.27 28.55C235.233 28.0024 235.309 27.4531 235.493 26.9362C235.678 26.4193 235.966 25.9459 236.342 25.5454C236.717 25.1448 237.17 24.8258 237.674 24.6081C238.178 24.3904 238.721 24.2787 239.27 24.28C239.749 24.2678 240.225 24.3526 240.67 24.5293C241.115 24.706 241.52 24.971 241.86 25.3082C242.2 25.6455 242.469 26.0481 242.649 26.4918C242.829 26.9355 242.918 27.4111 242.91 27.89H237.37C237.45 29.96 238.7 31.03 240.37 31.03C240.865 31.0375 241.353 30.9126 241.784 30.6683C242.215 30.4239 242.572 30.0689 242.82 29.64V29.64ZM237.35 27.1H240.58C240.58 26.1 240.13 25.1 239.03 25.1C238.18 25.11 237.48 25.67 237.35 27.1Z" />
        <path d="M250.46 31.39V27.78C250.46 26.42 249.78 25.91 248.7 25.91C248.192 25.9248 247.688 26.0055 247.2 26.15V31.39L248.37 32.66H243.65L244.92 31.39V26.29L243.73 25.61L247.2 24.14V25.69C248.173 24.8398 249.4 24.3368 250.69 24.26C251.94 24.26 252.76 25.03 252.76 26.68V31.39L254.03 32.66H249.31L250.46 31.39Z" />
        <path d="M254.61 28.55C254.584 27.9916 254.673 27.4337 254.87 26.9108C255.068 26.3878 255.37 25.9108 255.759 25.509C256.147 25.1072 256.614 24.7891 257.13 24.5743C257.646 24.3596 258.201 24.2526 258.76 24.26C259.383 24.2593 260 24.3857 260.573 24.6316C261.145 24.8775 261.661 25.2376 262.09 25.69L260.58 28.55L259.42 25.41C259.155 25.2522 258.849 25.1758 258.54 25.19C257.42 25.19 256.54 26.05 256.54 27.72C256.54 29.72 257.76 31.07 259.44 31.07C259.958 31.0944 260.473 30.9815 260.933 30.7427C261.393 30.5038 261.782 30.1476 262.06 29.71C262.067 30.144 261.983 30.5746 261.815 30.9747C261.646 31.3748 261.397 31.7356 261.082 32.0344C260.767 32.3332 260.394 32.5635 259.986 32.7108C259.577 32.8581 259.143 32.9191 258.71 32.89C258.152 32.8867 257.601 32.7703 257.089 32.548C256.577 32.3256 256.116 32.0018 255.733 31.5962C255.349 31.1906 255.052 30.7116 254.859 30.188C254.666 29.6645 254.582 29.1073 254.61 28.55V28.55Z" />
        <path d="M270.82 29.64C270.839 30.0901 270.76 30.5388 270.589 30.9555C270.418 31.3722 270.158 31.7468 269.828 32.0537C269.499 32.3606 269.107 32.5925 268.679 32.7334C268.251 32.8743 267.798 32.9209 267.35 32.87C266.789 32.8844 266.232 32.7807 265.713 32.5656C265.195 32.3505 264.728 32.0288 264.342 31.6214C263.957 31.214 263.661 30.73 263.474 30.2008C263.288 29.6717 263.215 29.1092 263.26 28.55C263.223 28.0024 263.299 27.4531 263.483 26.9362C263.668 26.4193 263.956 25.9459 264.332 25.5454C264.707 25.1448 265.16 24.8258 265.664 24.6081C266.168 24.3904 266.711 24.2787 267.26 24.28C267.73 24.2786 268.196 24.3708 268.631 24.5511C269.065 24.7314 269.46 24.9963 269.791 25.3303C270.122 25.6643 270.384 26.0609 270.56 26.4969C270.737 26.9329 270.825 27.3996 270.82 27.87H265.32C265.4 29.94 266.64 31.01 268.32 31.01C268.822 31.0286 269.319 30.9111 269.759 30.6698C270.199 30.4286 270.566 30.0728 270.82 29.64V29.64ZM265.34 27.1H268.57C268.57 26.1 268.13 25.1 267.03 25.1C266.17 25.11 265.47 25.67 265.33 27.1H265.34Z" />
        <path d="M272.1 31.98L272.8 29.4C273.55 30.66 274.6 32.03 275.8 32.03C276.31 32.03 276.71 31.79 276.71 31.28C276.71 30.77 276.37 30.4 274.81 29.67C272.94 28.79 272.23 27.99 272.23 26.78C272.23 25.22 273.47 24.26 275.23 24.26C276.24 24.2704 277.232 24.5279 278.12 25.01L277.66 27.48C276.52 25.62 275.83 25.1 275.05 25.1C274.27 25.1 274.05 25.45 274.05 25.96C274.05 26.47 274.58 26.85 275.75 27.43C277.75 28.43 278.54 29.13 278.54 30.38C278.54 31.93 277.31 32.98 275.49 32.98C274.294 32.936 273.128 32.5922 272.1 31.98V31.98Z" />
        <path d="M19.69 0.0200043H18.62V20.69H19.69V0.0200043Z" />
        <path d="M19.69 27.73H18.62V32.82H19.69V27.73Z" />
        <path d="M26.62 0.0200043H25.55V20.69H26.62V0.0200043Z" />
        <path d="M26.62 27.73H25.55V32.82H26.62V27.73Z" />
        <path d="M0 11.61L0 12.68L18.86 12.68V11.61L0 11.61Z" />
        <path d="M26.62 11.61V12.68H38.2V11.61H26.62Z" />
        <path d="M5.97997 0.0200043H4.90997V5.11H5.97997V0.0200043Z" />
        <path d="M5.97997 12.15H4.90997V32.82H5.97997V12.15Z" />
        <path d="M12.83 0.0200043H11.76V5.11H12.83V0.0200043Z" />
        <path d="M12.83 12.15H11.76V32.82H12.83V12.15Z" />
        <path d="M0 4.73L0 5.8L18.86 5.8V4.73L0 4.73Z" />
        <path d="M26.62 4.73V5.8L38.2 5.8V4.73L26.62 4.73Z" />
        <path d="M38.2 0.169998H32.57V5.8H38.2V0.169998Z" />
        <path d="M38.2 11.61H32.57V32.82H38.2V11.61Z" />
        <path d="M65.81 32.82L51.7 11.6H58.53L68.88 27.12H74.29V32.82H65.81Z" />
        <path d="M88.42 0.169998L102.53 21.39H95.69L85.31 5.81H79.94V0.169998H88.42Z" />
        <path d="M85.57 11.61H79.94V32.82H85.57V11.61Z" />
        <path d="M74.3 0.169998H68.67V21.47H74.3V0.169998Z" />
        <path d="M104.62 11.61H110.3V32.82H103.42L99.56 27.21H104.62V11.61Z" />
        <path d="M49.52 32.82H43.84V0.169998H50.72L54.58 5.79H49.52V32.82Z" />
        <path d="M110.3 0.169998H104.67V5.8H110.3V0.169998Z" />
        <path d="M0 27.19L0 28.26H5.44V27.19H0Z" />
        <path d="M0 20.32L0 21.39H5.44V20.32H0Z" />
        <path d="M12.46 27.19V28.26H29.79V27.19H12.46Z" />
        <path d="M12.46 20.32V21.39H29.79V20.32H12.46Z" />
      </g>
      <defs>
        <clipPath id="clip0_1005_1062">
          <rect width="335" height="37" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default function INNLogoBar() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { i18n } = useTranslation();

  return (
    <div className={classes.logoBarWrapper}>
      <div className={classes.logoBar}>
        <div className={classes.logo}>
          <a href="https://inn.no">
            {i18n.language === 'en' ? <INNLogoEN /> : <INNLogoNO />}
          </a>
        </div>
      </div>
    </div>
  );
}
