import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation, TFunction } from 'react-i18next';
import { createUseStyles, useTheme } from 'react-jss';
import { Form, Field } from 'react-final-form';

import Alert from 'components/alert';
import { Button, ButtonContainer } from 'components/button';
import { TableRowForm, TableRowFormFields, FormField } from 'components/form';
import GraphQLResponseParser from 'components/graphql';
import { ConfirmModal } from 'components/modal';
import { TextInput } from 'components/newForm';
import { PageSubSection } from 'components/page';
import Text from 'components/text';
import {
  IRoleGrant,
  PersonIdType,
  ElectionGroupRoleType,
  MutationResponse,
  ElectionGroup,
} from 'interfaces';
import { buttonize } from 'utils';
import { validateFeideId } from 'utils/validators';

const useStyles = createUseStyles((theme: any) => ({
  form: {
    display: 'flex',
  },
  formSection: {
    marginTop: '3rem',
    display: 'inline-block',
    marginRight: '8rem',
  },
  list: {
    display: 'inline-block',
    listStyleType: 'none',
    marginTop: '1rem',
  },
  removeButton: {
    background: 'url("/remove.svg") no-repeat right top 30%',
    backgroundSize: '1.4rem',
    height: '1.4rem',
    width: '1.4rem',
    paddingRight: '2.5rem',
    display: 'inline-block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  removeButtonDisabled: {
    backgroundColor: 'gray',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  feedback: {
    marginTop: '1.5rem',
  },
  feedbackError: {
    color: theme.formErrorTextColor,
  },
}));

const addRoleMutation = gql`
  mutation addElectionGroupRoleByIdentifier(
    $electionGroupId: UUID!
    $role: ElectionGroupRoleType!
    $idType: PersonIdType!
    $idValue: String!
  ) {
    addElectionGroupRoleByIdentifier(
      electionGroupId: $electionGroupId
      role: $role
      idType: $idType
      idValue: $idValue
    ) {
      success
      code
      message
    }
  }
`;

interface AddRoleVariables {
  electionGroupId: string;
  role: ElectionGroupRoleType;
  idType: PersonIdType;
  idValue: string;
}

interface AddRoleData {
  addElectionGroupRoleByIdentifier: MutationResponse;
}

const removeRoleMutation = gql`
  mutation removeElectionGroupRoleByGrant($grantId: UUID!) {
    removeElectionGroupRoleByGrant(grantId: $grantId) {
      success
      code
      message
    }
  }
`;

interface RemoveRoleVariables {
  grantId: string;
}

interface RemoveRoleData {
  removeElectionGroupRoleByGrant: MutationResponse;
}

function getPrincipalDisplayName(role: IRoleGrant): string {
  console.info(role);
  if (!role.principal || !role.principal.__typename) {
    return 'Error in roles...';
  }
  switch (role.principal.__typename) {
    case 'PersonPrincipal':
      return role.principal.person.displayName;
    case 'PersonIdentifierPrincipal':
      return role.principal.idValue;
    case 'GroupPrincipal':
      return role.principal.group.name;
    default:
      return role.grantId;
  }
}

function validateAddRoleForm(values: AddAdminFormValues, t: TFunction) {
  const errors = {} as { idValue: string };
  if (values.idValue) {
    if (!validateFeideId(values.idValue)) {
      errors.idValue = t('formErrors.invalidFeideId', { ns: 'common' });
    }
  } else {
    errors.idValue = t('formErrors.missingFeideId', { ns: 'common' });
  }
  return errors;
}

interface AddAdminFormValues {
  idValue: string;
}

interface AdminRolesFormProps {
  electionGroup: ElectionGroup;
  onClose: () => void;
}

export default function AdminRolesForm({
  electionGroup,
  onClose,
}: AdminRolesFormProps) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation(['admin', 'common']);

  const [roleToRemove, setRoleToRemove] = useState<IRoleGrant | null>(null);
  const [displayWarning, setDisplayWarning] = useState<boolean>(false);
  const [lastAdded, setLastAdded] = useState<string | null>(null);
  const [lastRemoved, setLastRemoved] = useState<string | null>(null);

  const [
    addRole,
    {
      data: addRoleData,
      error: addRoleError,
      loading: addRoleLoading,
      reset: addRoleReset,
    },
  ] = useMutation<AddRoleData, AddRoleVariables>(addRoleMutation, {
    refetchQueries: ['electionGroup'],
    awaitRefetchQueries: true,
  });

  const [
    removeRole,
    { data: removeRoleData, error: removeRoleError, reset: removeRoleReset },
  ] = useMutation<RemoveRoleData, RemoveRoleVariables>(removeRoleMutation, {
    refetchQueries: ['electionGroup'],
    awaitRefetchQueries: true,
  });

  const adminRoles = electionGroup.roles.filter(
    (role) => role.name === 'admin'
  );

  /**
   * The page has multiple alerts.
   * This functions closes the other alerts (if open).
   */
  const closeOtherAlerts = (type: 'add' | 'remove' | 'warning') => {
    switch (type) {
      case 'add':
        if (removeRoleData || removeRoleError) {
          removeRoleReset();
        }
        setDisplayWarning(false);
        break;
      case 'remove':
        if (addRoleData || addRoleError) {
          addRoleReset();
        }
        setDisplayWarning(false);
        break;
      case 'warning':
        if (addRoleData || addRoleError) {
          addRoleReset();
        }
        if (removeRoleData || removeRoleError) {
          removeRoleReset();
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div>
        <PageSubSection header={t('info.roles.electionAdmins')}>
          <GraphQLResponseParser
            data={removeRoleData?.removeElectionGroupRoleByGrant}
            errors={removeRoleError}
            errorMsg={t('info.roles.remoteAdminError', {
              target: lastRemoved,
            })}
            okMsg={t('info.roles.removeAdminOK', { target: lastRemoved })}
            onClose={removeRoleReset}
          />
          {displayWarning && (
            <Alert severity="warning" onClose={() => setDisplayWarning(false)}>
              {t('info.roles.lastAdminWarning')}
            </Alert>
          )}
          <Text>{t('info.roles.electionAdminsDesc')}</Text>
          <div className={classes.form}>
            <div className={classes.formSection}>
              <Text bold>{t('info.roles.singleUsers')}</Text>
              <ul className={classes.list}>
                {adminRoles.map((role) => (
                  <li key={role.grantId}>
                    <Text inline>{getPrincipalDisplayName(role)}</Text>
                    <div
                      className={classes.removeButton}
                      {...buttonize(
                        adminRoles.length === 1
                          ? () => {
                              closeOtherAlerts('warning');
                              setDisplayWarning(true);
                            }
                          : () => setRoleToRemove(role),
                        'Enter'
                      )}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </PageSubSection>
        <PageSubSection>
          <Form
            onSubmit={({ idValue }: AddAdminFormValues) =>
              addRole({
                variables: {
                  electionGroupId: electionGroup.id,
                  role: 'admin',
                  idType: 'feide_id',
                  idValue: idValue,
                },
                onCompleted: () => {
                  closeOtherAlerts('add');
                  setLastAdded(idValue);
                },
              })
            }
            validate={(values: AddAdminFormValues) =>
              validateAddRoleForm(values, t)
            }
          >
            {(formProps) => {
              const { handleSubmit, valid, pristine, submitting, form } =
                formProps;
              const handleSubmitAndReset = async (e: any) => {
                e.preventDefault();
                await handleSubmit();
                if (valid) {
                  form.reset();
                  form.resetFieldState('idValue');
                }
              };
              return (
                <form onSubmit={handleSubmitAndReset}>
                  <TableRowForm header={t('info.roles.addAdmin')}>
                    <GraphQLResponseParser
                      data={addRoleData?.addElectionGroupRoleByIdentifier}
                      errors={addRoleError}
                      errorMsg={t('info.roles.addAdminError', {
                        target: lastAdded,
                      })}
                      okMsg={t('info.roles.addAdminOK', {
                        target: lastAdded,
                      })}
                      onClose={addRoleReset}
                    />
                    <TableRowFormFields>
                      <FormField inline>
                        <Field
                          name="idValue"
                          component={TextInput}
                          large
                          placeholder={t('idTypes.feide_id', { ns: 'common' })}
                        />
                      </FormField>
                      <Button
                        action={handleSubmitAndReset}
                        disabled={
                          pristine || !valid || addRoleLoading || submitting
                        }
                        height="4.5rem"
                        showSpinner={addRoleLoading || submitting}
                        text={t('actions.add', { ns: 'common' })}
                      />
                    </TableRowFormFields>
                  </TableRowForm>
                </form>
              );
            }}
          </Form>
        </PageSubSection>
        <ButtonContainer noTopMargin>
          <Button
            action={onClose}
            text={t('actions.close', { ns: 'common' })}
            secondary
          />
        </ButtonContainer>
      </div>

      {roleToRemove && adminRoles.length > 1 && (
        <ConfirmModal
          confirmAction={() =>
            removeRole({
              variables: { grantId: roleToRemove.grantId },
              onCompleted: () => {
                setLastRemoved(getPrincipalDisplayName(roleToRemove));
                closeOtherAlerts('remove');
                setRoleToRemove(null);
              },
              onError: () => setRoleToRemove(null),
            })
          }
          closeAction={() => setRoleToRemove(null)}
          header={t('info.roles.confirmRemovalHeader')}
          body={t('info.roles.confirmRemovalDesc', {
            target: getPrincipalDisplayName(roleToRemove),
          })}
          confirmText={t('actions.remove', { ns: 'common' })}
          closeText={t('actions.cancel', { ns: 'common' })}
        />
      )}
    </>
  );
}
