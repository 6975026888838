const white = '#FFFFFF';
const black = '#000000';

const usnGrey = '#D2D7D7';

const USNTheme = {
  footerBackgroundColor: usnGrey,
  footerTextColor: black,
  headerDescColor: black,
  headerLogoBarColor: usnGrey,
  headerMainAreaColor: usnGrey,
  loginPageBgColor: white,
  dropDownDesktopMenuColor: black,
  linkColor: black,
  navMenuTextColor: black,
};

export default USNTheme;
