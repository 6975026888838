import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';

import { getMaxCandidatesInEditedList } from '../utils';

import {
  PageExpandableSubSection,
  PageSubSection,
} from 'components/page/PageSection';
import { EditListCandidate, Election } from 'interfaces';
import { ListCandidateReviewItem } from '../listCandidateItem';

const useStyles = createUseStyles((theme: any) => ({
  list: {
    marginTop: '1.5rem',
    marginRight: '2rem',
    [theme.breakpoints.notMobileQuery]: {
      marginTop: '3rem',
    },
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '3rem',
  },
}));

interface EditedVoteListProps {
  editedCandidates: EditListCandidate[];
  election: Election;
  otherListCandidates: EditListCandidate[];
}

export default function EditedVoteList(props: EditedVoteListProps) {
  const { editedCandidates, election, otherListCandidates } = props;
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  const deletedCandidates = editedCandidates.filter(
    (candidate) => candidate.userDeleted
  );
  const selectedCandidates = editedCandidates.filter(
    (candidate) => !candidate.userDeleted
  );

  const ballotRules = election.meta.ballotRules;
  const nrOfSeats = election.meta.candidateRules.seats;

  let ballotCandidates: EditListCandidate[] = [];
  let outsideBallotCandidates: EditListCandidate[] = [];

  if (ballotRules.votes === 'nr_of_seats') {
    const maxCandidatesInBallot = getMaxCandidatesInEditedList(
      nrOfSeats,
      editedCandidates,
      otherListCandidates
    );
    let nrOfValidCandidatesInBallot = 0;

    selectedCandidates.forEach((candidate) => {
      if (nrOfValidCandidatesInBallot < maxCandidatesInBallot) {
        if (candidate.userDeleted) {
          ballotCandidates.push(candidate);
        } else {
          ballotCandidates.push(candidate);
          nrOfValidCandidatesInBallot++;
        }
      } else {
        outsideBallotCandidates.push(candidate);
      }
    });
  } else {
    ballotCandidates = editedCandidates;
  }

  return (
    <div>
      <ul className={classes.list}>
        {ballotCandidates.map((candidate, index) => (
          <ListCandidateReviewItem
            candidate={candidate.candidate}
            cumulated={candidate.userCumulated}
            displayCumulateStatus={true}
            key={candidate.candidate.id}
            priority={index}
          />
        ))}
      </ul>

      {otherListCandidates.length > 0 && (
        <PageSubSection header={t('voter.listVote.otherListCandidates')}>
          <ul className={classes.list}>
            {otherListCandidates.map((candidate) => (
              <ListCandidateReviewItem
                candidate={candidate.candidate}
                cumulated={false}
                displayCumulateStatus={false}
                electionList={candidate.sourceList}
                key={candidate.candidate.id}
              />
            ))}
          </ul>
        </PageSubSection>
      )}

      {deletedCandidates.length > 0 && (
        <PageExpandableSubSection
          header={t('voter.listVote.deletedCandidateHeader')}
        >
          <div className={classes.listContainer}>
            <ul className={classes.list}>
              {deletedCandidates.map((candidate) => (
                <ListCandidateReviewItem
                  key={candidate.candidate.id}
                  cumulated={false}
                  candidate={candidate.candidate}
                  deleted
                />
              ))}
            </ul>
          </div>
        </PageExpandableSubSection>
      )}

      {ballotRules.votes === 'nr_of_seats' &&
        outsideBallotCandidates.length > 0 && (
          <PageExpandableSubSection
            header={t('voter.listVote.tooManyCandidates.header')}
          >
            <ul className={classes.list}>
              {outsideBallotCandidates.map((candidate) => (
                <ListCandidateReviewItem
                  key={candidate.candidate.id}
                  candidate={candidate.candidate}
                  cumulated={candidate.userCumulated}
                  displayCumulateStatus={true}
                  outside={true}
                />
              ))}
            </ul>
          </PageExpandableSubSection>
        )}
    </div>
  );
}
