import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz'
import { nb, enUS, nn } from 'date-fns/locale'

import { appTimezone } from 'appConfig';

interface IProps {
  dateTime: Date | string;
  longDate: boolean;
}

const Date: React.FunctionComponent<IProps> = (props: IProps) => {
  const { i18n, t } = useTranslation();

  const localeMap = {
    en: enUS,
    nb: nb,
    nn: nn,
  };
  const selectedLocale = localeMap[i18n.language]

  if (!props.dateTime) {
    return <span>{t('election.valueNotSet')}</span>;
  }

  const zonedTime = utcToZonedTime(props.dateTime, appTimezone);
  const date = props.longDate
    ? format(zonedTime, 'PP', { locale: selectedLocale })
    : format(zonedTime, 'P', { locale: selectedLocale });
  return <span>{date}</span>;
};

export default Date;
