import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageSection } from 'components/page';
import Icon from 'components/icon';
import { ScreenSizeConsumer } from 'providers/ScreenSize';
import { Candidate, Election } from 'interfaces';

import BallotButtons from './BallotButtons';
import {
  CandidateList,
  CandidateListItem,
  CandidateInfo,
} from './CandidateList';
import ElectionInfo from './electionInfo';
import HelpSubSection from './HelpSubSection';

interface IProps {
  alternatives: Candidate[];
  selectedAlternative: Candidate | null;
  onSelectAlternative: (candidate: Candidate) => void;
  onDeselectAlternative: () => void;
  election: Election;
  reviewBallotEnabled: boolean;
  onGoBackToSelectVoterGroup: () => void;
  onReviewBallot: () => void;
  onBlankVote: () => void;
}

const PollVoteBallot: React.FunctionComponent<IProps> = (props) => {
  const {
    alternatives,
    selectedAlternative,
    onSelectAlternative,
    onDeselectAlternative,
    reviewBallotEnabled,
    onGoBackToSelectVoterGroup,
    onReviewBallot,
    onBlankVote,
    election,
  } = props;

  const { t } = useTranslation();

  const showBlankButton =
    election.meta.ballotRules.allowBlank === undefined
      ? true
      : election.meta.ballotRules.allowBlank;

  const candidatePoll = election.meta.candidateType === 'candidate';

  let helpTextTags = ['pollElect.voteHelpYouMaySelectOnlyOne'];
  let helpHeader = t('pollElec.voteHelpHeader');
  let helpDesc = t('pollElec.voteHelpDesc');
  let helpText = [t('pollElec.voteHelpYouMaySelectOnlyOne')];

  if (candidatePoll) {
    helpTextTags = ['voter.majorityVoteHelpYouMaySelectOnlyOne'];
    helpHeader = t('voter.majorityVoteHelpHeader');
    helpDesc = t('voter.majorityVoteHelpDesc');
    helpText = [t('voter.majorityVoteHelpYouMaySelectOnlyOne')];
  }

  if (showBlankButton) {
    helpTextTags.push('voter.canVoteBlank');
    helpText.push(t('voter.canVoteBlank'));
  }

  return (
    <ScreenSizeConsumer>
      {({ screenSize }) => (
        <PageSection noBorder>
          <ElectionInfo election={election} />
          <HelpSubSection
            header={helpHeader}
            desc={helpDesc}
            helpTextTags={helpTextTags}
            helpText={helpText}
          >
            <CandidateList>
              {alternatives.map((alternative) => {
                let toggleSelectAction = () => onSelectAlternative(alternative);
                if (selectedAlternative === alternative) {
                  toggleSelectAction = () => onDeselectAlternative();
                }

                return (
                  <CandidateListItem key={alternative.id}>
                    {selectedAlternative === alternative ? (
                      <Icon
                        type="radioButtonCircleSelected"
                        title={t('pollElec.ballot.removeChoice', {
                          alternative: alternative.name,
                        })}
                        custom={
                          screenSize !== 'mobile' && screenSize !== 'sm'
                            ? { small: true }
                            : false
                        }
                        onClick={toggleSelectAction}
                      />
                    ) : (
                      <Icon
                        type="radioButtonCircle"
                        title={t('pollElec.ballot.selectChoice', {
                          alternative: alternative.name,
                        })}
                        custom={
                          screenSize !== 'mobile' && screenSize !== 'sm'
                            ? { small: true }
                            : false
                        }
                        onClick={toggleSelectAction}
                      />
                    )}
                    <CandidateInfo candidate={alternative} infoUrl />
                  </CandidateListItem>
                );
              })}
            </CandidateList>
          </HelpSubSection>
          <BallotButtons
            onGoBackToSelectVoterGroup={onGoBackToSelectVoterGroup}
            onBlankVote={onBlankVote}
            showBlankVoteButton={showBlankButton}
            reviewBallotEnabled={reviewBallotEnabled}
            onReviewBallot={onReviewBallot}
          />
        </PageSection>
      )}
    </ScreenSizeConsumer>
  );
};

export default PollVoteBallot;
