import { gql, useMutation } from '@apollo/client';
import arrayMutators from 'final-form-arrays';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import { disableGenderQuotaButton } from 'appConfig';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from 'components/table';
import { CheckBox } from 'components/form';
import { NumberInputRF, FormButtons } from 'components/form';
import { PageSubSection } from 'components/page';
import { ElectionBaseSettingsInput, ElectionGroup, Election } from 'interfaces';

const updateBaseSettings = gql`
  mutation UpdateBaseSettings(
    $id: UUID!
    $hasGenderQuota: Boolean!
    $elections: [ElectionBaseSettingsInput]!
  ) {
    updateBaseSettings(
      id: $id
      hasGenderQuota: $hasGenderQuota
      elections: $elections
    ) {
      ok
    }
  }
`;

interface UpdateBaseSettingsData {
  updateBaseSettings: {
    ok: boolean;
  };
}

interface ElectionsBaseSettings {
  id: string;
  hasGenderQuota: boolean;
  elections: ElectionBaseSettingsInput[];
}

function handleFormSubmit(
  values: ElectionsBaseSettings,
  initialValues: ElectionsBaseSettings,
  updateSettings: (props: any) => void,
  onSubmit: () => void
) {
  if (!('hasGenderQuota' in values) || values.hasGenderQuota === undefined) {
    // hasGenderQuota is required by the GraphQL query. Add it as false if missing.
    values.hasGenderQuota = false;
  }

  if (JSON.stringify(values) === JSON.stringify(initialValues)) {
    // Only call the api with changes.
    onSubmit();
    return;
  }

  updateSettings({
    variables: {
      id: values.id,
      hasGenderQuota: values.hasGenderQuota,
      elections: values.elections.map((e: ElectionBaseSettingsInput) => ({
        id: e.id,
        active: e.active,
        seats: e.seats ? e.seats : 0,
        substitutes: e.substitutes ? e.substitutes : 0,
      })),
    },
    onCompleted: () => {
      onSubmit();
    },
  });
}

interface BaseElectionSettingsFormProps {
  electionGroup: ElectionGroup;
  onSubmit: () => void;
  closeAction: () => void;
}

export default function BaseElectionSettingsForm({
  closeAction,
  electionGroup,
  onSubmit,
}: BaseElectionSettingsFormProps) {
  const {
    i18n: { language },
    t,
  } = useTranslation(['admin', 'common']);
  const initialValues: ElectionsBaseSettings = {
    elections: electionGroup.elections.map((e) => ({
      id: e.id,
      active: e.active,
      name: e.name,
      seats: e.meta.candidateRules.seats,
      substitutes: e.meta.candidateRules.substitutes,
    })),
    hasGenderQuota: electionGroup.meta.candidateRules.candidateGender,
    id: electionGroup.id,
  };
  const { elections } = electionGroup;

  const [updateSettings, { loading }] = useMutation<
    UpdateBaseSettingsData,
    ElectionsBaseSettings
  >(updateBaseSettings, {
    refetchQueries: ['electionGroup'],
    awaitRefetchQueries: true,
  });

  if (electionGroup.meta.candidateType === 'party_list') {
    return (
      <Form
        onSubmit={(values: ElectionsBaseSettings) =>
          handleFormSubmit(values, initialValues, updateSettings, onSubmit)
        }
        keepDirtyOnReinitialize
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
      >
        {(formProps: FormRenderProps) => {
          const { handleSubmit, values, valid, submitting } = formProps;
          return (
            <form onSubmit={handleSubmit}>
              <Table>
                <TableHeader>
                  <TableHeaderRow>
                    <TableHeaderCell>
                      {t('general.group', { ns: 'common' })}
                    </TableHeaderCell>
                    <TableHeaderCell>
                      {t('info.baseSettings.nrOfCandidates')}
                    </TableHeaderCell>
                  </TableHeaderRow>
                </TableHeader>
                <FieldArray<Election> name="elections">
                  {({ fields }) => (
                    <TableBody>
                      {fields.map((election, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <Field
                                name={`${election}.active`}
                                type="checkbox"
                                label={elections[index].name[language]}
                              >
                                {({ input, label }) => (
                                  <CheckBox {...input} disabled label={label} />
                                )}
                              </Field>
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`${election}.seats`}
                                component={NumberInputRF}
                                disabled={
                                  !values.elections[index].active ||
                                  submitting ||
                                  loading
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </FieldArray>
              </Table>
              <FormButtons
                submitting={loading}
                saveAction={handleSubmit}
                closeAction={closeAction}
                submitDisabled={!valid || submitting || loading}
              />
            </form>
          );
        }}
      </Form>
    );
  }

  return (
    <Form
      onSubmit={(values: ElectionsBaseSettings) =>
        handleFormSubmit(values, initialValues, updateSettings, onSubmit)
      }
      keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
    >
      {(formProps: FormRenderProps) => {
        const { handleSubmit, values, valid, submitting } = formProps;
        return (
          <form onSubmit={handleSubmit}>
            <Table>
              <TableHeader>
                <TableHeaderRow>
                  <TableHeaderCell>
                    {t('general.group', { ns: 'common' })}
                  </TableHeaderCell>
                  <TableHeaderCell>
                    {t('info.baseSettings.nrOfCandidates')}
                  </TableHeaderCell>
                  <TableHeaderCell>
                    {t('info.baseSettings.nrOfCoCandidates')}
                  </TableHeaderCell>
                </TableHeaderRow>
              </TableHeader>
              <FieldArray<Election> name="elections">
                {({ fields }) => (
                  <TableBody>
                    {fields.map((election, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Field
                              name={`${election}.active`}
                              type="checkbox"
                              label={elections[index].name[language]}
                            >
                              {({ input, label }) => (
                                <CheckBox
                                  {...input}
                                  disabled={submitting || loading}
                                  label={label}
                                />
                              )}
                            </Field>
                          </TableCell>
                          <TableCell>
                            <Field
                              name={`${election}.seats`}
                              component={NumberInputRF}
                              disabled={
                                !values.elections[index].active ||
                                submitting ||
                                loading
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Field
                              name={`${election}.substitutes`}
                              component={NumberInputRF}
                              disabled={
                                !values.elections[index].active ||
                                submitting ||
                                loading
                              }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </FieldArray>
            </Table>
            <PageSubSection header={t('election.quotas', { ns: 'common' })}>
              <Field
                name="hasGenderQuota"
                type="checkbox"
                label={t('info.baseSettings.hasGenderQuota')}
              >
                {({ input, label }) => (
                  <CheckBox
                    {...input}
                    disabled={disableGenderQuotaButton || submitting || loading}
                    label={label}
                  />
                )}
              </Field>
            </PageSubSection>
            <FormButtons
              submitting={loading}
              saveAction={handleSubmit}
              closeAction={closeAction}
              submitDisabled={!valid || submitting || loading}
            />
          </form>
        );
      }}
    </Form>
  );
}
