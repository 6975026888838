import { Election, ElectionList, Candidate } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { createUseStyles, useTheme } from 'react-jss';

import { PageExpandableSubSection } from 'components/page/PageSection';

import {
  ListCandidateItem,
  ListCandidateReviewItem,
} from '../listCandidateItem';
import { getMaxCandidatesInList } from '../utils';

const useStyles = createUseStyles((theme: any) => ({
  list: {
    marginTop: '1.5rem',
    marginRight: '2rem',
    [theme.breakpoints.notMobileQuery]: {
      marginTop: '3rem',
    },
  },
}));

interface CleanVoteListProps {
  election: Election;
  selectedList: ElectionList;
}

export default function CleanVoteList(props: CleanVoteListProps) {
  const { election, selectedList } = props;
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  const ballotRules = election.meta.ballotRules;
  const nrOfSeats = election.meta.candidateRules.seats;

  /**
   * Sort the candidates by there given priority
   */
  const listCandidatesSorted = [...selectedList.candidates].sort(
    (a, b) => a.priority - b.priority
  );

  let ballotCandidates: Candidate[] = [];
  let outsideBallotCandidates: Candidate[] = [];

  if (ballotRules.votes === 'nr_of_seats') {
    const maxCandidatesInBallot = getMaxCandidatesInList(
      nrOfSeats,
      selectedList
    );

    ballotCandidates = listCandidatesSorted.slice(0, maxCandidatesInBallot);
    outsideBallotCandidates = listCandidatesSorted.slice(maxCandidatesInBallot);
  } else {
    ballotCandidates = listCandidatesSorted;
  }

  return (
    <>
      <ul className={classes.list}>
        {ballotCandidates.map((candidate, index) => (
          <ListCandidateItem
            key={candidate.id}
            candidate={candidate}
            priority={index}
          />
        ))}
      </ul>

      {ballotRules.votes === 'nr_of_seats' &&
        outsideBallotCandidates.length > 0 && (
          <PageExpandableSubSection
            header={t('voter.listVote.tooManyCandidates.header')}
          >
            <ul className={classes.list}>
              {outsideBallotCandidates.map((candidate) => (
                <ListCandidateReviewItem
                  key={candidate.id}
                  candidate={candidate}
                  cumulated={false}
                  displayCumulateStatus={true}
                  outside={true}
                />
              ))}
            </ul>
          </PageExpandableSubSection>
        )}
    </>
  );
}
