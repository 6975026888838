const white = '#FFFFFF';
const uitBlue = '#003349';

const UiTTheme = {
  headerMainAreaColor: uitBlue,
  headerLogoBarColor: uitBlue,
  headerDescColor: white,
  footerBackgroundColor: uitBlue,
  footerTextColor: white,
  loginPageBgColor: white,
  // tableHeaderBg: uibBlueGreen,
  // primaryBtnFocusedBorderColor: uibBlueGreen,
  // inlineDropdownBottomBorderColor: uibBlueGreen,
  // helpBoxBorderColor: uibBlueGreen,
  // stepperColor: uibBlueGreen,
};

export default UiTTheme;
