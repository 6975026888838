import { makeElObj, makeElObjData } from './makeElectionObjects';
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz';

import { buttonize } from './a11y';
import { appTimezone } from '../appConfig';

import {
  allEqual,
  allEqualForAttrs,
  findObjIndex,
  isObjEmpty,
  isObject,
  objPropsToSnakeCase,
  objPropsToCamelCase,
  objPropsToArray,
  objsEqual,
  reorderArray,
} from './helpers';

import { translateBackendError, joinStringsWithCommaAndAnd } from './i18n';

const sleep = async (ms: number) => {
  await new Promise((resolve) => setTimeout(resolve, ms));
};

const ISODateTimeToTimeZoneAdjustedISODate = (dateTime: string) => {
  const zonedDateTime = utcToZonedTime(new Date(dateTime), appTimezone);
  return format(zonedDateTime, 'yyyy-MM-dd');
};

const ISODateTimeToTimeZoneAdjustedTime = (dateTime: string) => {
  const zonedDateTime = utcToZonedTime(new Date(dateTime), appTimezone);
  return format(zonedDateTime, 'HH:mm');
};

const DateAndTimeToISODTWithTimeZonedOffset = (date: string, time: string) => {
  const utcDate = utcToZonedTime(new Date(`${date}T${time}`), 'UTC');
  return format(utcDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
};

export {
  allEqual,
  allEqualForAttrs,
  buttonize,
  DateAndTimeToISODTWithTimeZonedOffset,
  findObjIndex,
  isObjEmpty,
  isObject,
  ISODateTimeToTimeZoneAdjustedISODate,
  ISODateTimeToTimeZoneAdjustedTime,
  joinStringsWithCommaAndAnd,
  makeElObj,
  makeElObjData,
  objsEqual,
  objPropsToArray,
  objPropsToCamelCase,
  objPropsToSnakeCase,
  translateBackendError,
  reorderArray,
  sleep,
};
