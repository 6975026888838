const white = '#FFFFFF';

const hvlBlue = '#004357';

const HVLTheme = {
  footerBackgroundColor: hvlBlue,
  footerTextColor: white,
  headerDescColor: white,
  headerLogoBarColor: hvlBlue,
  headerMainAreaColor: hvlBlue,
  loginPageBgColor: white,
  dropDownDesktopMenuColor: white,
  linkColor: white,
  navMenuTextColor: white,
};

export default HVLTheme;
