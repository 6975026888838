import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import Page from 'components/page/Page';
import { PageSection } from 'components/page';
import { isCreatingNewElectionVar } from 'cache';

import NewElectionForm from './components/NewElectionForm';

const electionTemplateQuery = gql`
  query {
    electionTemplate
  }
`;

const createNewElectionGroupMutation = gql`
  mutation CreateNewElectionGroup(
    $ouId: UUID!
    $template: Boolean!
    $templateName: String!
    $name: ElectionName
  ) {
    createNewElectionGroup(
      ouId: $ouId
      template: $template
      templateName: $templateName
      nameDict: $name
    ) {
      electionGroup {
        id
      }
    }
  }
`;

interface CreateNewElectionData {
  createNewElectionGroup: {
    electionGroup: {
      id: string;
    };
  };
}

interface CreateNewElectionSettings {
  $ouId: string;
  $template: boolean;
  templateName: string;
  name: any;
}

export default function NewElection() {
  const { t } = useTranslation(['admin']);
  const [currentValues, setCurrentValues] = useState<object>({});
  let history = useHistory();

  const { data, loading, error } = useQuery(electionTemplateQuery);

  const [
    createNewElectionGroup,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<CreateNewElectionData, CreateNewElectionSettings>(
    createNewElectionGroupMutation,
    {
      onCompleted: (mutationData: any) => {
        isCreatingNewElectionVar(true);
        const { electionGroup } = mutationData.createNewElectionGroup;
        history.push(`/admin/elections/${electionGroup.id}/info`);
      },
    }
  );
  if (loading) {
    return <div>{t('createNewElection.loadingTemplates')}</div>;
  }
  if (error) {
    return <div>{}</div>;
  }
  if (mutationLoading) {
    return <div>{t('createNewElection.creating')}</div>;
  }
  if (mutationError) {
    return (
      <div>{t('createNewElection.createError', { error: mutationError })}</div>
    );
  }

  return (
    <Page header={t('createNewElection.header')}>
      <PageSection header={t('createNewElection.selectType')}>
        <NewElectionForm
          initialValues={currentValues}
          updateValues={setCurrentValues}
          electionTemplate={data.electionTemplate}
          submitAction={(values: any) =>
            createNewElectionGroup({ variables: values })
          }
          cancelAction={() => history.push('/admin')}
        />
      </PageSection>
    </Page>
  );
}
