import React from 'react';

import { appInst } from 'appConfig';

import HiOFLogoBar from './logoBars/HiOF';
import HiMoldeLogoBar from './logoBars/HiMolde';
import HVLLogoBar from './logoBars/HVL';
import INNLogoBar from './logoBars/INN';
import KHiOLogoBar from './logoBars/KHiO';
import NMBULogoBar from './logoBars/NMBU';
import NMHLogoBar from './logoBars/NMH';
import OsloMetLogoBar from './logoBars/OsloMet';
import UiALogoBar from './logoBars/UiA';
import UiBLogoBar from './logoBars/UiB';
import UiOLogoBar from './logoBars/UiO';
import UiSLogoBar from './logoBars/UiS';
import UiTLogoBar from './logoBars/UiT';
import USNLogoBar from './logoBars/USN';
import NHHLogoBar from './logoBars/NHH';

const LogoBar: React.FunctionComponent = () => {
  switch (appInst) {
    case 'hiof':
      return <HiOFLogoBar />;
    case 'himolde':
      return <HiMoldeLogoBar />;
    case 'hvl':
      return <HVLLogoBar />;
    case 'inn':
      return <INNLogoBar />;
    case 'khio':
      return <KHiOLogoBar />;
    case 'nhh':
      return <NHHLogoBar />;
    case 'nmbu':
      return <NMBULogoBar />;
    case 'nmh':
      return <NMHLogoBar />;
    case 'oslomet':
      return <OsloMetLogoBar />;
    case 'uia':
      return <UiALogoBar />;
    case 'uib':
      return <UiBLogoBar />;
    case 'uio':
      return <UiOLogoBar />;
    case 'uis':
      return <UiSLogoBar />;
    case 'uit':
      return <UiTLogoBar />;
    case 'usn':
      return <USNLogoBar />;
    default:
      return <UiOLogoBar />;
  }
};

export default LogoBar;
