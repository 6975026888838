import { CircularProgress } from '@mui/material';

interface IProps {
  darkStyle?: boolean;
  size?: string;
  thin?: boolean;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  color?: string;
}

export default function Spinner(props: IProps) {
  return (
    <CircularProgress
      size={props.size ? props.size : '2.5rem'}
      sx={{
        position: 'relative',
        display: 'inline-block',
        border: props.darkStyle
          ? '3px solid rgba(200,200,200)'
          : '3px solid rgba(255,255,255, .3)',
        borderWidth: props.thin ? '2px' : '3px',
        borderRadius: '80%',
        borderTopColor: props.darkStyle ? 'rgba(50,50,50)' : '#fff',
        marginLeft: props.marginLeft ? props.marginLeft : 0,
        marginRight: props.marginRight ? props.marginRight : 0,
        marginTop: props.marginTop ? props.marginTop : 0,
        animationDuration: '0.8s',
        color: props.color ? props.color : 'white',
      }}
    />
  );
}
