const white = '#FFFFFF';
const uisBlueLogo = '#0A3CA0';

const UiSTheme = {
  headerMainAreaColor: uisBlueLogo,
  headerLogoBarColor: uisBlueLogo,
  headerDescColor: white,
  footerBackgroundColor: uisBlueLogo,
  footerTextColor: white,
  loginPageBgColor: white,
};

export default UiSTheme;
